// Import files and libraries
import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { Calendar } from 'primereact/calendar';
import { MultiSelect } from 'primereact/multiselect';
import { ProgressBar } from 'primereact/progressbar';


//others
import { IconBack, IconEdit, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData } from '../../../store/organization/reducer';
import { setDisabledButton, setProfileLoader, setShowCamera, setSupportingDocumentPlaceholder, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionProfileUpload, actionSDUpload, actionCreate, actionUpdate, actionFileById } from '../../../store/organization/action'


// custom component
import FilePreview from '../../../component/file-preview';
import { base64ToFile, checkFileUpload, checkFileUploadProfile, fileAccept, formatFileSize, getFileExtension, profileAccept, truncateFileName } from '../../../helper/common-helper';
import { ATTACHMENT_TYPE, DEFAULT_USER_ROLE, ENVIRONMENT, PERMISSION_KEY, PERMISSION_LIST, PERMISSION_PAGE_KEY, RECORD_STATUS, REGEXP, ROLE_LIST, TOAST_TYPE, validatePermission } from '../../../config/constant';

import { showToast } from '../../../helper/toast-service-helper';
import { setFormDefaultPermission } from '../../../store/auth/reducer';
import { actionFileUpload } from '../../../store/general/action';
import MultipleFilePreview from '../../../component/multiple-file-upload';
import PermissionList from '../../../component/permission-list';
import FormStatus from '../../../component/form-status';
import { actionGetLocationList } from '../../../store/location/action';
import { getDatabaseDateOnly, getDisplayDateOnly } from '../../../helper/date-helper';
import CameraUpload from '../../../component/camera-upload';
import { Dropdown as ButtonDropdown } from 'react-bootstrap';

const OrganizationFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState({});
    const [editForm, setEditForm] = useState(false);
    const [cameraUploadFile, setCameraUploadFile] = useState("");

    const fileProfile = useRef(null);
    // Function to trigger file input click
    const handleFileProfileUploadClick = () => {
        fileProfile.current.click(); // Trigger the file input click
    };
    const fileSD = useRef(null);
    // Function to trigger file input click
    const handleFilefileSDUploadClick = () => {
        fileSD.current.click(); // Trigger the file input click
    };
    const { formData, updateId, formModel } = useSelector((state) => state.organization);
    const { locationList } = useSelector((state) => state.location);
    const { disabledButton, profile, supportingDocuments, profileLoader } = useSelector((state) => state.general);
    const { formDefaultPermission, defaultUserPermission, userData } = useSelector((state) => state.auth);

    useEffect(() => {
        setErrors({});
        setEditForm(updateId ? false : true);
        dispatch(actionGetLocationList('all'));
    }, [formModel]);


    // form validation schema
    let schema = Yup.object().shape({
        name: Yup.string()
        .min(1, 'name must be at least 1 character long')
        .max(50, 'name must be at most 50 characters long')
        .matches(REGEXP.alphabetRegExp, 'name can only contain alphabetic characters') // Allows letters and spaces only
        .matches(REGEXP.noConsecutiveSpaceRegExp, 'name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
        .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
        .trim()
        .required('name is required'),
        registration_number: Yup.string()
            .max(15, 'Registration Number must be at most 15 characters long')
            .matches(/^[a-zA-Z0-9]*$/, 'Registration Number must be alphanumeric') // Ensures only letters and numbers
            .required('Registration Number is required'),
        email: Yup.string()
            .max(50, 'Email must be at most 50 characters long')
            .matches(REGEXP.emailRegExp, 'Invalid email address')
            .required('Email is required'),
        phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(15, 'Phone number must be at most 15 characters long')
            .min(7, 'Phone number must be at least 7 characters long')
            .required('Phone number is required'),
        locations: Yup.array()
            .of(Yup.string().required('Location is required'))
            .min(1, 'At least one Location is required')
            .required('Location are required'),
        address: Yup.string()
            .trim()
            .max(255, 'Address must be at most 255 characters long')
            .test('not-only-numbers', 'Address cannot be only numbers', (value) => {
                return !/^[0-9]+$/.test(value); // Ensure the address is not only numbers
            })
            .test('contains-letter-or-number', 'Address must contain at least one letter', (value) => {
                return /[a-zA-Z]/.test(value); // Ensure there is at least one letter or number
            })
            .required('Address is required'),

        gem_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Gem Target is not valid')
            .max(20, 'Monthly Gem Target must be at most 20 characters long')
            .required('Monthly Gem Target is required'),
        metal_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Metal Target is not valid')
            .max(20, 'Monthly Metal Target must be at most 20 characters long')
            .required('Monthly Metal Target is required'),
        customer_name: Yup.string()
            .min(1, 'Customer Name must be at least 1 character long')
            .max(50, 'Customer Name must be at most 50 characters long')
            .matches(REGEXP.alphabetRegExp, 'Customer Name can only contain alphabetic characters') // Allows letters and spaces only
            .matches(REGEXP.noConsecutiveSpaceRegExp, 'Customer Name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
            .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
            .trim()
            .required('Customer Name is required'),
        customer_email: Yup.string()
            .max(50, 'Email must be at most 50 characters long')
            .matches(REGEXP.emailRegExp, 'Invalid email address')
            .required('Email is required'),
        customer_phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(15, 'Phone number must be at most 15 characters long')
            .min(7, 'Phone number must be at least 7 characters long')
            .required('Phone number is required'),
        customer_designation: Yup.string()
            .max(50, 'Designation must be at most 50 characters long')
            .matches(/^(?!^[^a-zA-Z0-9]*$).*$/, 'Designation must contain at least one letter or number')
            .matches(/^(?!^[0-9]*$).*$/, 'Designation cannot be only numbers')
            .required('Designation is required')

    });

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            let supporting_documents = [];
            if (Object.values(supportingDocuments).length > 0) {
                Object.values(supportingDocuments).map(doc => {
                    if (doc?.isNew) {
                        supporting_documents.push(doc.id);
                    }
                });
            }

            const finalData = {
                name: formData.name,
                registration_number: formData.registration_number,
                date_of_formation: getDatabaseDateOnly(formData.date_of_formation),
                email: formData.email,
                phone_number: formData.phone_number,
                locations: formData.locations,
                address: formData.address,
                gem_target: formData.gem_target,
                metal_target: formData.metal_target,
                profile: profile !== null ? profile.file.id : null,
                active: formData.active,
                locations: formData.locations,
                supporting_documents,
                permissions: Object.values(formDefaultPermission).filter(permission => permission !== 0),
                customer_name: formData.customer_name,
                customer_email: formData.customer_email,
                customer_phone_number: formData.customer_phone_number,
                customer_designation: formData.customer_designation
            };

            if (updateId) { //check the form action update or create
                // update data with update id
                dispatch(actionUpdate(finalData, updateId));
            } else {
                // create form data
                dispatch(actionCreate(finalData));
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        let data;

        // if (name === 'date_of_formation') {
        //     value = getDisplayDateOnly(value);
        // }

        data = {
            ...formData,
            [name]: value,
        };
        dispatch(setFormData(data));
        validateField(name, value);
    };

    const uploadFile = (e) => {
        const { name } = e.target;
        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        if (name === 'profile') {
            const ckf = checkFileUploadProfile(checkFile);
            if (ckf) {
                const formData = new FormData();
                formData.append('file', checkFile);
                dispatch(setProfileLoader(true));
                dispatch(actionFileUpload(formData));
            }
        } else {
            Array.from(e.target.files).forEach((file) => {
                const ckf = checkFileUpload(file);
                if (ckf) {
                    let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                    let ext = getFileExtension(file.name);
                    dispatch(setSupportingDocumentPlaceholder({
                        inx, file: {
                            name: file.name,
                            type: file.type,
                            ext,
                            size: formatFileSize(file.size),
                            isNew: true
                        }
                    }));
                    const formSDData = new FormData();
                    formSDData.append('file', file); // Append the file to the FormData object

                    dispatch(actionFileUpload(formSDData, inx, name));
                }
            });
        }
    }

    const handleCapture = (e) => {

        const file = base64ToFile(e);
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        if (cameraUploadFile === 'profile') {
            dispatch(setProfileLoader(true));
            dispatch(actionFileUpload(formData));

        } else {
            let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
            let ext = 'png';
            dispatch(setSupportingDocumentPlaceholder({
                inx, file: {
                    name: file.name,
                    type: file.type,
                    ext,
                    size: formatFileSize(file.size),
                    isNew: true
                }
            }));
            const formSDData = new FormData();
            formSDData.append('file', file); // Append the file to the FormData object

            dispatch(actionFileUpload(formSDData, inx, ATTACHMENT_TYPE.ADMIN_SUPPORT_DOCUMENT));
        }

    }
    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-10 mb-3">
                        <div className='d-flex align-items-center'>
                            <IconBack
                                width={32}
                                height={32}
                                onIconClick={closeModal}
                                customClass='cursor-pointer'
                            />
                            <h3 className='flex-shrink-1 ms-2 mb-0'>{updateId ? 'Update Organisation' : 'Create New Organisation'}</h3>
                        </div>
                    </div>
                    {updateId && !editForm && defaultUserPermission && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.ORGANIZATION, PERMISSION_KEY.UPDATE) && (
                        <div className="col-md-2 mb-3 text-end">
                            <button
                                className="btn btn-purple-primary me-3"
                                type='button'
                                onClick={e => setEditForm(true)}
                            >
                                <IconEdit customClass='text-white' /> Edit
                            </button>
                        </div>
                    )}
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <div className='col-md-auto d-flex'>
                            <div className='col-md-auto pb-3'>
                                {profile ? (
                                    <div className='placeholder-image-bg position-relative'>
                                        <img src={`data:${profile?.type};base64,${profile?.base64}`} />
                                        {profileLoader && (<span className="profile-loader">
                                            <Spinner width={36} height={36} />
                                        </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className='placeholder-image-bg position-relative'>
                                        <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                        {profileLoader && (<span className="profile-loader">
                                            <Spinner width={36} height={36} />
                                        </span>
                                        )}
                                    </div>
                                )}
                            </div>
                            {editForm && (
                                <div className='col-md-auto align-self-center ms-3'>
                                    <input type="file" accept={profileAccept} ref={fileProfile} name="profile" onChange={uploadFile} className='d-none' />
                                    <ButtonDropdown>
                                        <ButtonDropdown.Toggle className='btn btn-orange-primary'>
                                            Upload
                                        </ButtonDropdown.Toggle>
                                        <ButtonDropdown.Menu>
                                            <ButtonDropdown.Item onClick={e => {
                                                setCameraUploadFile('profile')
                                                dispatch(setShowCamera(true));
                                            }}>Capture from camera</ButtonDropdown.Item>
                                            <ButtonDropdown.Item onClick={handleFileProfileUploadClick}>

                                                Upload from your computer</ButtonDropdown.Item>
                                        </ButtonDropdown.Menu>
                                    </ButtonDropdown>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <Accordion defaultActiveKey={['0', '1', '2']} alwaysOpen className='custom-accordiant'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><strong>Organisation information</strong></Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Name</label>
                                                <input type="text" className="form-control "
                                                    name="name"
                                                    disabled={!editForm}
                                                    value={formData.name}
                                                    onChange={handleChange}
                                                    placeholder='Enter the name'
                                                />
                                                {errors.name && <span className="error-message">{errors.name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Registration Number</label>
                                                <input type="text" className="form-control "
                                                    name="registration_number"
                                                    disabled={!editForm}
                                                    value={formData.registration_number}
                                                    onChange={handleChange}
                                                    placeholder='Enter the registration number'
                                                />
                                                {errors.registration_number && <span className="error-message">{errors.registration_number}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Date of Formation</label>
                                                <Calendar
                                                    disabled={!editForm}
                                                    value={formData.date_of_formation ? new Date(formData.date_of_formation) : ''}
                                                    onChange={handleChange}
                                                    dateFormat="dd/mm/yy"
                                                    name="date_of_formation"
                                                    className='date-picker-input'
                                                    placeholder='Enter the date of formation'
                                                    showIcon
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Email address</label>
                                                <input type="text" className="form-control "
                                                    name="email"
                                                    disabled={userData.user_role_id === DEFAULT_USER_ROLE.SUPER_ADMIN ? !editForm : updateId}
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder='Enter the email address'
                                                />
                                                {errors.email && <span className="error-message">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control "
                                                    name="phone_number"
                                                    disabled={userData.user_role_id === DEFAULT_USER_ROLE.SUPER_ADMIN ? !editForm : updateId}
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    placeholder='Enter the phone number'
                                                />
                                                {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label className='w-100'>Mining Location</label>
                                                <MultiSelect
                                                    disabled={!editForm}
                                                    value={formData.locations}
                                                    name='locations'
                                                    onChange={handleChange}
                                                    options={locationList}
                                                    optionLabel="location_name"
                                                    optionValue="id"
                                                    display="chip"
                                                    // maxSelectedLabels={3}
                                                    placeholder="Select locations"
                                                    className="custom-multiselect w-100"                                                   
                                                
                                                />
                                                {errors.locations && <span className="error-message">{errors.locations}</span>}
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Metal Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="metal_target"
                                                        disabled={!editForm}
                                                        value={formData.metal_target}
                                                        onChange={handleChange}
                                                        placeholder='Enter the monthly metal target'
                                                    />
                                                    <span className="input-group-text" id="metal_targe">Gm</span>
                                                </div>
                                                {errors.metal_target && <span className="error-message">{errors.metal_target}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Gem Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="gem_target"
                                                        disabled={!editForm}
                                                        value={formData.gem_target}
                                                        onChange={handleChange}
                                                        placeholder='Enter the monthly gem target'
                                                    />
                                                    <span className="input-group-text" id="gem_targe">Carat</span>
                                                </div>
                                                {errors.gem_target && <span className="error-message">{errors.gem_target}</span>}
                                            </div>
                                        </div>
                                        
                                        <div className="clearfix"></div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Address</label>
                                                <textarea className="form-control "
                                                    name="address"
                                                    disabled={!editForm}
                                                    value={formData.address}
                                                    onChange={handleChange}
                                                    rows={6}
                                                    placeholder={`Address Line 1\nAddress Line 2\nCity\nState\nCountry\nPincode`}
                                                >
                                                    {formData.address}
                                                </textarea>
                                                {errors.address && <span className="error-message">{errors.address}</span>}
                                            </div>
                                        </div>
                                      
                                        <div className="clearfix"></div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label className='w-100'>Supporting documents</label>
                                                {editForm && (
                                                    <>
                                                        <ButtonDropdown>
                                                            <ButtonDropdown.Toggle className='btn btn-orange-primary-outline text-orange'>
                                                                Upload
                                                            </ButtonDropdown.Toggle>
                                                            <ButtonDropdown.Menu>
                                                                <ButtonDropdown.Item onClick={e => {
                                                                    setCameraUploadFile(ATTACHMENT_TYPE.ORG_SUPPORT_DOCUMENT)
                                                                    dispatch(setShowCamera(true));
                                                                }}>Capture from camera</ButtonDropdown.Item>
                                                                <ButtonDropdown.Item onClick={handleFilefileSDUploadClick}>

                                                                    Upload from your computer</ButtonDropdown.Item>
                                                            </ButtonDropdown.Menu>
                                                        </ButtonDropdown>


                                                        <input accept={fileAccept} type="file" ref={fileSD} className='d-none' name={ATTACHMENT_TYPE.ORG_SUPPORT_DOCUMENT} multiple onChange={uploadFile} />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <MultipleFilePreview files={supportingDocuments} showDelete={editForm} />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><strong>Contact person information</strong></Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Name</label>
                                                <input type="text" className="form-control "
                                                    name="customer_name"
                                                    disabled={!editForm}
                                                    value={formData.customer_name}
                                                    onChange={handleChange}
                                                    placeholder='Enter the contact person name'
                                                />
                                                {errors.customer_name && <span className="error-message">{errors.customer_name}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Email</label>
                                                <input type="text" className="form-control "
                                                    name="customer_email"
                                                    disabled={!editForm}
                                                    value={formData.customer_email}
                                                    onChange={handleChange}
                                                    placeholder='Enter the contact person email'
                                                />
                                                {errors.customer_email && <span className="error-message">{errors.customer_email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Phone</label>
                                                <input type="text" className="form-control "
                                                    name="customer_phone_number"
                                                    disabled={!editForm}
                                                    value={formData.customer_phone_number}
                                                    onChange={handleChange}
                                                    placeholder='Enter the contact person phone'
                                                />
                                                {errors.customer_phone_number && <span className="error-message">{errors.customer_phone_number}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Contact Person Designation</label>
                                                <input type="text" className="form-control "
                                                    name="customer_designation"
                                                    disabled={!editForm}
                                                    value={formData.customer_designation}
                                                    onChange={handleChange}
                                                    placeholder='Enter the contact person designation'
                                                />
                                                {errors.customer_designation && <span className="error-message">{errors.customer_designation}</span>}
                                            </div>
                                        </div>

                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header><strong>Permission</strong></Accordion.Header>
                                <Accordion.Body>
                                    {parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.SUPER_ADMIN ? (
                                        <PermissionList editForm={editForm} />
                                    ) : (
                                        <PermissionList editForm={false} />
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='col-12 mb-3'>
                        <FormStatus
                            onStatusClick={handleChange}
                            active={formData.active}
                            id={updateId}
                            editForm={editForm}
                        />
                    </div>
                    <Divider />
                    <div className="col-12 text-end">
                        <div className="form-group mb-3">
                            {editForm && (
                                <>
                                    <button
                                        className="btn  btn-purple-primary-outline me-3"
                                        type='button'
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={disabledButton}
                                        className="btn  btn-purple-primary"
                                        type='submit'
                                    >
                                        {disabledButton ? <Spinner /> : null} {updateId ? 'Update' : 'Create'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
            <CameraUpload onImgCallBack={handleCapture} />
            <FilePreview />
        </>
    );
};


export default OrganizationFormModel;
