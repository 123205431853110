import React from 'react';
import { useDispatch } from 'react-redux';
import { actionGetFileById } from '../store/general/action';
import { ProgressBar } from 'primereact/progressbar';
import { truncateFileName } from '../helper/common-helper';
import { IconDelete, Spinner } from '../svg-icons';
import { confirmDialog } from 'primereact/confirmdialog';
import { actionDeleteBySlug } from '../store/file/action';


const SingleFilePreview = ({ file, cusClassName = "",inx=null,showDeleteBtn=false,fieldName="" }) => {
    
    // create a dispatch
    const dispatch = useDispatch();

    const viewFile = (file) => {
        
        if (file.file_type === 'application/pdf' || (file.file_type).startsWith('image/')) {
            dispatch(actionGetFileById(file.slug, 'sd'));
        } else if (file?.id) {
            downloadFile(file.base64, file.original_name, file.file_type);
        }
    };


    const downloadFile = (base64Data, fileName, fileType) => {
        // Create a Blob from the base64 string
        const byteCharacters = atob(base64Data); // Decode base64
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });

        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(link.href);
    };
    const deleteFile =(file,inx,fieldName)=>{
         // before call a API ask the user confimation
    confirmDialog({
        message: (
          <>
            Do you want to delete the file <strong>{file.name}</strong>?
          </>
        ),
        header: 'Delete Confimation',
        accept: async () => {
          // if user confirmed the call the delete API
         dispatch( actionDeleteBySlug(file.slug,fieldName,inx));
        }
      });
    }

    return (
        <div className={`form-group mb-3 cursor-pointer ${cusClassName}`} >
            <div className="d-flex align-content-center">
                <div onClick={() => viewFile(file)} className='flex-shrink-1 align-self-center me-2 position-relative'>
                    <div className='upload-thumb-icon rounded'></div>
                    <div className='img-layer position-absolute top-0 start-0 w-100 h-100 text-center'>
                        <div className='position-absolute top-50 start-50 translate-middle file-ext-name'>
                            {file?.base64  && (file.file_type).startsWith('image/') ? (
                                <img className='w-30' src={`data:${file.file_type};base64,${file.base64}`} />
                            ) : (
                                <>
                                    {file?.file?.ext}
                                    {file?.ext}
                                </>)}

                            {file?.downloading && <Spinner />}
                        </div>
                    </div>
                </div>
                <div className='w-100'>
                    <div className="d-flex ">
                        <div onClick={() => viewFile(file)} className='me-2'>
                            {/* {JSON.stringify(file)} Jay */}
                            {file?.downloading && (
                                <>
                                    <p className={`download txt-dec m-0 li-hi-20`}>{file.name}</p>
                                    {"Downloading..."}
                                </>
                            )}
                            {file?.uploadProgress && (
                                <>
                                    <p className={`donwload txt-dec m-0 li-hi-20`}>{truncateFileName(file.file.name)}</p>
                                    {file.uploadProgress < 100 ? <ProgressBar value={file.uploadProgress}></ProgressBar> : "Processing..."}
                                </>
                            )}
                            {file?.id && !file?.downloading && (
                                <>
                                    <p className='preiv txt-dec m-0 li-hi-36'>{truncateFileName(file.original_name)}</p>
                                </>
                            )}
                            {/* {file?.placeholder && <p className={`txt-dec m-0 li-hi-20`}>{truncateFileName(file.file.name)}</p>}
                            {file?.id && <p className='txt-dec m-0 li-hi-36'>{truncateFileName(file.original_name)}</p>}
                            {file?.placeholder && (
                                <>
                                    {file.uploadProgress < 100 ? <ProgressBar value={file.uploadProgress}></ProgressBar> : "Processing..."}
                                </>
                            )} */}
                        </div>
                        <div className='flex-shrink-1 align-self-center'>
                            <p className='m-0'>
                                {file?.file?.size && <span>{file.file.size}</span>}
                                {file?.size && <span>{file.size}</span>}
                            </p>
                        </div>
                        { showDeleteBtn && (<div className='ms-2 flex-shrink-1 align-self-center'>
                            <button type='button' className='btn btn-sm' onClick={e=>deleteFile(file,inx,fieldName)}>
                                <IconDelete/>
                            </button>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SingleFilePreview;
