// Import files and libraries
import React, { useEffect, useRef, useState } from "react";
import {
  IconBack,
  IconCurrentStep,
  IconRejectStep,
  IconWorkLocation,
  Spinner,
} from "../../../svg-icons";
import GetOrderItemStatus from "../../../component/order-item-status";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setComment,
  setCurrentItem,
  setShowComment,
} from "../../../store/trade/reducer";
import {
  ATTACHMENT_TYPE,
  DEFAULT_USER_ROLE,
  ENVIRONMENT,
  ORDER_ITEM_AR_STATUS,
  ORDER_ITEM_STATUS,
  TOAST_TYPE,
} from "../../../config/constant";
import ItemDetails from "../../../component/item-details";
import { Dialog } from "primereact/dialog";
import {
  setClearGeneralState,
  setDisabledButton,
  setIAPlaceholder,
  setShowCamera,
  setSupportingDocumentPlaceholder,
  updateLoaderStatus,
} from "../../../store/general/reducer";
import {
  actionGetOrderItemById,
  actionUpdateItemStatus,
} from "../../../store/trade/action";
import * as Yup from "yup";
import { getDisplayDateTime } from "../../../helper/date-helper";
import { showToast } from "../../../helper/toast-service-helper";
import MultipleFilePreview from "../../../component/multiple-file-upload";
import FilePreview from "../../../component/file-preview";
import {
  actionClearData,
  actionFileUpload,
  actionGetFileById,
} from "../../../store/general/action";
import {
  base64ToFile,
  checkFileUpload,
  fileAccept,
  formatFileSize,
  getFileExtension,
  isMobileScreen,
  profileAccept,
  truncateFileName,
} from "../../../helper/common-helper";
import SingleFilePreview from "../../../component/single-file-upload";
import { Dropdown as ButtonDropdown } from "react-bootstrap";
import CameraUpload from "../../../component/camera-upload";

const TradingItemApproval = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showARButton, setShowARButton] = useState(false);
  const [actionStatus, setActionStatus] = useState(null);
  const [errors, setErrors] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [cameraUploadFile, setCameraUploadFile] = useState("");

  const fileCustomerPhoto = useRef(null);
  const handleFileCustomerPhotoUploadClick = () => {
    fileCustomerPhoto.current.click(); // Trigger the file input click
  };

  const fileMetalPhoto = useRef(null);
  const handleFileMetalPhotoUploadClick = () => {
    fileMetalPhoto.current.click(); // Trigger the file input click
  };

  const fileWeightPhoto = useRef(null);
  const handleFileWeightPhotoUploadClick = () => {
    fileWeightPhoto.current.click(); // Trigger the file input click
  };

  const filePurityPhoto = useRef(null);
  const handleFilePurityPhotoUploadClick = () => {
    filePurityPhoto.current.click(); // Trigger the file input click
  };

  const fileSD = useRef(null);
  const handleFilefileSDUploadClick = () => {
    fileSD.current.click(); // Trigger the file input click
  };

  const { currentItem, showComment, comments } = useSelector(
    (state) => state.trade
  );

  const { userData } = useSelector((state) => state.auth);

  const {
    disabledButton,
    urlParams,
    customer_photo,
    metal_photo,
    weight_photo,
    purity_photo,
    supportingDocuments,
    customer_metal_photo,
  } = useSelector((state) => state.general);

  // form validation schema
  let schema = Yup.object().shape({
    comments: Yup.string()
      .trim()
      .required("Comments is required")
      .matches(/\S+/, "Comments cannot contain only spaces"),
  });
  // On page function call get the page data and if any table action changes call the api
  useEffect(() => {
    if (urlParams?.view && urlParams?.type && urlParams.type === "order-item") {
      dispatch(setClearGeneralState());
      dispatch(actionGetOrderItemById(urlParams.view));
    }
  }, [urlParams]);

  //validate the fields
  const validateField = async (name, value) => {
    try {
      await Yup.reach(schema, name).validate(value);
      setErrors({ ...errors, [name]: "" });
    } catch (validationError) {
      setErrors({ ...errors, [name]: validationError.message });
    }
  };

  //validate the form
  const validateForm = async () => {
    try {
      await schema.validate({ comments }, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const newErrors = validationErrors.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return false;
    }
  };

  const handleChangeComment = (e) => {
    const { name, value } = e.target;

    dispatch(setComment(value));
    validateField(name, value);
  };
  const goBack = () => {
    dispatch(setCurrentItem(null));
    navigate(-1); // Navigate to the previous page
  };

  useEffect(() => {
    if (currentItem) {
      generateARButton(currentItem.item_approval);
    }
  }, [currentItem]);

  const generateARButton = (ci) => {
    const checkAgent = ci.filter(
      (ap) => ap.user_role_id === 3 && ap.approval_status === 1
    );
    if (checkAgent.length > 0 && userData.user_role_id === 3) {
      setShowARButton(true);
      return;
    }

    const checkAgentApproved = ci.filter(
      (ap) => ap.user_role_id === 3 && ap.approval_status === 2
    );
    const checkAdmin = ci.filter(
      (ap) => ap.user_role_id === 2 && ap.approval_status === 1
    );

    if (
      checkAdmin.length > 0 &&
      checkAgentApproved.length > 0 &&
      userData.user_role_id === 2
    ) {
      setShowARButton(true);
      return;
    }

    const checkAdminApproved = ci.filter(
      (ap) => ap.user_role_id === 2 && ap.approval_status === 2
    );
    const checkSuperAdminApproved = ci.filter(
      (ap) => ap.user_role_id === 1 && ap.approval_status === 1
    );
    if (
      checkAgentApproved.length > 0 &&
      checkAdminApproved.length > 0 &&
      checkSuperAdminApproved.length > 0 &&
      userData.user_role_id === 1
    ) {
      setShowARButton(true);
      return;
    }
  };

  const itemStatus = (status) => {
    let initHasErrors = false;
    if (userData.user_role_id === DEFAULT_USER_ROLE.AGENT) {
      if (!customer_photo) {
        showToast(
          TOAST_TYPE.ERROR,
          `Please select the customer photo`,
          "top-right"
        );
        initHasErrors = true;
      }
      if (!metal_photo) {
        showToast(
          TOAST_TYPE.ERROR,
          `Please select the metal photo`,
          "top-right"
        );
        initHasErrors = true;
      }
      if (!weight_photo) {
        showToast(
          TOAST_TYPE.ERROR,
          `Please select the weight photo`,
          "top-right"
        );
        initHasErrors = true;
      }
      if (!purity_photo) {
        showToast(
          TOAST_TYPE.ERROR,
          `Please select the purity photo`,
          "top-right"
        );
        initHasErrors = true;
      }
      if (initHasErrors) {
        return;
      }
    }
    setActionStatus(status);
    dispatch(setShowComment(true));
  };
  const updateOrderItemStatus = async () => {
    // get the form status
    const isValid = await validateForm();

    if (isValid) {
      let supporting_documents = [];
      if (Object.values(supportingDocuments).length > 0) {
        Object.values(supportingDocuments).map((doc) => {
          if (doc?.isNew) {
            supporting_documents.push(doc.id);
          }
        });
      }
      let data = {
        order_item_id: currentItem.order_item_id,
        stage_id: currentItem.item_approval.length,
        status: actionStatus,
        comments,
        customer_photo: customer_photo?.id,
        metal_photo: metal_photo?.id,
        weight_photo: weight_photo?.id,
        purity_photo: purity_photo?.id,
        supporting_documents,
      };
      
      dispatch(setDisabledButton(true));
      dispatch(updateLoaderStatus(true));
      dispatch(actionUpdateItemStatus(data, currentItem.slug, navigate));
    }
  };

  const uploadFile = (e) => {
    const { name } = e.target;
    const checkFile = e.target.files[0]; // Get the selected file
    if (!checkFile) return;
    if (
      name === "customer_photo" ||
      name === "metal_photo" ||
      name === "weight_photo" ||
      name === "purity_photo"
    ) {
      const ckf = checkFileUpload(checkFile);
      if (ckf) {
        let ext = getFileExtension(checkFile.name);
        dispatch(
          setIAPlaceholder({
            name,
            file: {
              name: truncateFileName(checkFile.name),
              type: checkFile.type,
              ext,
              size: formatFileSize(checkFile.size),
              isNew: true,
            },
          })
        );

        const formSDData = new FormData();
        formSDData.append("file", checkFile); // Append the file to the FormData object

        dispatch(actionFileUpload(formSDData, 0, name));
      }
    } else {
      Array.from(e.target.files).forEach((file) => {
        const ckf = checkFileUpload(file);
        if (ckf) {
          let inx = "file_" + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
          let ext = getFileExtension(file.name);
          dispatch(
            setSupportingDocumentPlaceholder({
              inx,
              file: {
                name: file.name,
                type: file.type,
                ext,
                size: formatFileSize(file.size),
                isNew: true,
              },
            })
          );
          const formSDData = new FormData();
          formSDData.append("file", file); // Append the file to the FormData object

          dispatch(actionFileUpload(formSDData, inx, ATTACHMENT_TYPE.ORDER_ITEM_SUPPORT_DOCUMENT));
        }
      });
    }
  };

  const handleCapture = (e) => {
    const file = base64ToFile(e);
    if (!file) return;

    const name = cameraUploadFile;

    if (
      name === "customer_photo" ||
      name === "metal_photo" ||
      name === "weight_photo" ||
      name === "purity_photo"
    ) {
      let ext = "png";
      dispatch(
        setIAPlaceholder({
          name,
          file: {
            name: truncateFileName(file.name),
            type: file.type,
            ext,
            size: formatFileSize(file.size),
            isNew: true,
          },
        })
      );

      const formSDData = new FormData();
      formSDData.append("file", file); // Append the file to the FormData object

      dispatch(actionFileUpload(formSDData, 0, name));
    } else {
      let inx = "file_" + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
      let ext = "png";
      dispatch(
        setSupportingDocumentPlaceholder({
          inx,
          file: {
            name: file.name,
            type: file.type,
            ext,
            size: formatFileSize(file.size),
            isNew: true,
          },
        })
      );
      const formSDData = new FormData();
      formSDData.append("file", file); // Append the file to the FormData object

      dispatch(actionFileUpload(formSDData, inx, name));
    }
  };

  const viewFile = (file) => {
    
    if (file.file_type === 'application/pdf' || (file.file_type).startsWith('image/')) {
        dispatch(actionGetFileById(file.slug, 'sd'));
    }
};
  return (
    <div className="container-fluid">
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12 mb-3">
              <div className="d-flex align-items-center">
                <IconBack
                  width={32}
                  height={32}
                  onIconClick={goBack}
                  customClass="cursor-pointer"
                />
                <h3 className="ms-2 flex-shrink-1 mb-0">
                  Trading Item:{" "}
                  {currentItem && currentItem.order_item_row_ref_number}
                </h3>
              </div>
            </div>
            <div className="row mb-5">
              {/* Column for All Cards */}
              <div className="col-md-1 col-4 align-self-center over-flow-hide">
                {/* First Card - Image Placeholder */}
                {customer_metal_photo ? (
                  <div className="placeholder-item-image-bg cursor-pointer" onClick={e=>viewFile(customer_metal_photo)}>
                    <img
                      className="object-fit"
                      src={`data:${customer_metal_photo?.type};base64,${customer_metal_photo?.base64}`}
                      alt="Metal Photo"
                    />
                  </div>
                ) : metal_photo ? (
                  <div className="placeholder-item-image-bg cursor-pointer" onClick={e=>viewFile(metal_photo)}>
                    <img
                      className="object-fit"
                      src={`data:${metal_photo?.type};base64,${metal_photo?.base64}`}
                      alt="Metal Photo"
                    />
                  </div>
                ) : (
                  <div className="placeholder-item-image-bg">
                    <img
                      className="object-fit"
                      src={`${ENVIRONMENT.BASE_URL}assets/images/no-image.png`}
                      alt="placeholder"
                    />
                  </div>
                )}
              </div>
              <div className="col-md-10 col-8  align-self-center">
                <table className="table-bg-white table-borderless table-sm">
                  <tbody>
                    <tr>
                      <td className="pe-2">Customer ID:</td>
                      <td className="ps-2">
                        <a className="link">
                          {currentItem && currentItem.customer_row_ref_number}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2">Agent ID:</td>
                      <td className="ps-2">
                        <a className="link">
                          {currentItem && currentItem.agent_row_ref_number}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2">Trading ID:</td>
                      <td className="ps-2">
                        {currentItem && currentItem.row_ref_number}
                      </td>
                    </tr>
                    <tr>
                      <td className="pe-2">Status:</td>
                      <td className="ps-2">
                        {currentItem && (
                          <GetOrderItemStatus
                            status={currentItem.order_item_status}
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* Stepper start */}
            <div className="col-md-12 mt-0 ">
              <div className="bg-stepper-line position-relative">
                <div className="row stepper">
                  <div className="col-md-4 mb-3 text-center">
                    <p className="m-0 text-nowrap ">
                      {currentItem &&
                        currentItem.item_approval.map((iap, inx) => {
                          if (
                            parseInt(iap.approval_status) ===
                            ORDER_ITEM_AR_STATUS.REVIEW &&
                            parseInt(iap.user_role_id) ===
                            DEFAULT_USER_ROLE.AGENT
                          ) {
                            return (
                              <IconCurrentStep
                                key={inx + "agent"}
                                width={40}
                                height={40}
                                customClass="top-2"
                              />
                            );
                          } else if (
                            parseInt(iap.approval_status) ===
                            ORDER_ITEM_AR_STATUS.APPROVED &&
                            parseInt(iap.user_role_id) ===
                            DEFAULT_USER_ROLE.AGENT
                          ) {
                            return (
                              <IconCurrentStep
                                key={inx + "agent"}
                                width={40}
                                height={40}
                                bgColor="#10B981"
                                customClass="top-2"
                              />
                            );
                          } else if (
                            parseInt(iap.approval_status) ===
                            ORDER_ITEM_AR_STATUS.REJECTED &&
                            parseInt(iap.user_role_id) ===
                            DEFAULT_USER_ROLE.AGENT
                          ) {
                            return (
                              <IconRejectStep
                                key={inx + "agent"}
                                width={40}
                                height={40}
                                customClass="top-2"
                              />
                            );
                          }
                        })}

                      <span className="ms-1" key={"m"}>
                        Agent Verification
                      </span>
                    </p>
                  </div>
                  <div className="col-md-4 mb-3">
                    {currentItem &&
                      currentItem.item_approval.map((iap, inx) => {
                        // default orange
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.REVIEW &&
                          parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN
                        ) {
                          return (
                            <p className="m-0 text-nowrap " key={inx + "admin"}>
                              <IconCurrentStep
                                customClass="top-2"
                                width={40}
                                height={40}
                              />
                              <span className="ms-1">Admin Review</span>
                            </p>
                          );
                        }

                        //approved
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.APPROVED &&
                          parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN
                        ) {
                          return (
                            <p className="m-0 text-nowrap " key={inx + "admin"}>
                              <IconCurrentStep
                                width={40}
                                height={40}
                                bgColor="#10B981"
                                customClass="top-2"
                              />
                              <span className="ms-1">Admin Review</span>
                            </p>
                          );
                        }

                        // rejected
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.REJECTED &&
                          parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN
                        ) {
                          return (
                            <p className="m-0 text-nowrap " key={inx + "admin"}>
                              <IconRejectStep
                                customClass="top-2"
                                width={40}
                                height={40}
                              />
                              <span className="ms-1">Admin Review</span>
                            </p>
                          );
                        }

                        // only number
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.REVIEW &&
                          parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.AGENT
                        ) {
                          return (
                            <p key={inx + "admin"} className="m-0 text-nowrap ">
                              <span className="default-step me-1 float-start">
                                02
                              </span>
                              <span className="ms-1 lh-40">Admin Review</span>
                            </p>
                          );
                        }
                      })}
                  </div>
                  <div className="col-md-4 mb-3">
                    {currentItem &&
                      currentItem.item_approval.map((iap, inx) => {
                        // default orange
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.REVIEW &&
                          parseInt(iap.user_role_id) ===
                          DEFAULT_USER_ROLE.SUPER_ADMIN
                        ) {
                          return (
                            <p
                              className="m-0 text-nowrap "
                              key={inx + "super-admin"}
                            >
                              <IconCurrentStep
                                width={40}
                                height={40}
                                customClass="top-2"
                              />
                              <span className="ms-1">Super Admin Review -</span>
                            </p>
                          );
                        }

                        //approved
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.APPROVED &&
                          parseInt(iap.user_role_id) ===
                          DEFAULT_USER_ROLE.SUPER_ADMIN
                        ) {
                          return (
                            <p
                              className="m-0 text-nowrap "
                              key={inx + "super-admin"}
                            >
                              <IconCurrentStep
                                width={40}
                                height={40}
                                bgColor="#10B981"
                                customClass="top-2"
                              />
                              <span className="ms-1">Super Admin Review -</span>
                            </p>
                          );
                        }

                        // rejected
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.REJECTED &&
                          parseInt(iap.user_role_id) ===
                          DEFAULT_USER_ROLE.SUPER_ADMIN
                        ) {
                          return (
                            <p
                              className="m-0 text-nowrap "
                              key={inx + "super-admin"}
                            >
                              <IconRejectStep
                                width={40}
                                height={40}
                                customClass="top-2"
                              />
                              <span className="ms-1">Super Admin Review -</span>
                            </p>
                          );
                        }

                        // only number
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.REVIEW &&
                          parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.ADMIN
                        ) {
                          return (
                            <p
                              key={inx + "super-admin"}
                              className="m-0 text-nowrap "
                            >
                              <span className="default-step me-1 float-start">
                                03
                              </span>
                              <span className="ms-1 lh-40">
                                Super Admin Review
                              </span>
                            </p>
                          );
                        }
                        // only number
                        if (
                          parseInt(iap.approval_status) ===
                          ORDER_ITEM_AR_STATUS.REVIEW &&
                          parseInt(iap.user_role_id) === DEFAULT_USER_ROLE.AGENT
                        ) {
                          return (
                            <p
                              key={inx + "super-admin"}
                              className="m-0 text-nowrap "
                            >
                              <span className="default-step me-1 float-start">
                                03
                              </span>
                              <span className="ms-1 lh-40">
                                Super Admin Review
                              </span>
                            </p>
                          );
                        }
                      })}
                  </div>
                </div>
              </div>
            </div>
            {/* Stepper end */}

            <div className="col-md-12 mt-5">
              <h5>
                <strong>Product Details</strong>
              </h5>
              {currentItem && currentItem && (
                <ItemDetails
                  cc={currentItem.customer_contribution}
                  mc={currentItem.mc_contribution}
                  items={[currentItem]}
                  previewDetails={true}
                />
              )}
            </div>
            <div className="col-md-12 mt-5">
              <h5>
                <strong>Uploaded Documents</strong>
              </h5>
              <div className="row">
                {/* metal photo */}
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <label className="w-100">Metal photo</label>

                    {/* Only show upload button and validation if metal photo doesn't already exist */}
                    {currentItem &&
                      !metal_photo &&
                      showARButton &&
                      ![
                        ORDER_ITEM_STATUS.APPROVED.id,
                        ORDER_ITEM_STATUS.REJECTED.id,
                        ORDER_ITEM_STATUS.AGENT_REJECTED.id,
                        ORDER_ITEM_STATUS.ADMIN_REJECTED.id,
                      ].includes(currentItem.order_item_status) && (
                        <>
                          <ButtonDropdown>
                            <ButtonDropdown.Toggle className="btn btn-orange-primary-outline cursor-pointer">
                              Upload
                            </ButtonDropdown.Toggle>
                            <ButtonDropdown.Menu>
                              <ButtonDropdown.Item
                                onClick={(e) => {
                                  setCameraUploadFile("metal_photo");
                                  dispatch(setShowCamera(true));
                                }}
                              >
                                Capture from camera
                              </ButtonDropdown.Item>
                              <ButtonDropdown.Item onClick={handleFileMetalPhotoUploadClick}>
                                Upload from your computer
                              </ButtonDropdown.Item>
                            </ButtonDropdown.Menu>
                          </ButtonDropdown>
                          <input
                            accept={profileAccept}
                            ref={fileMetalPhoto}
                            onChange={uploadFile}
                            type="file"
                            name="metal_photo"
                            className="d-none"
                          />
                        </>
                      )}

                    {/* Display the preview or placeholder */}
                    {metal_photo ? (
                      <SingleFilePreview cusClassName="mt-2" file={metal_photo} fieldName="metal_photo" showDeleteBtn={userData.user_role_id===DEFAULT_USER_ROLE.AGENT && currentItem?.order_item_status ===ORDER_ITEM_STATUS.NEW.id ?true:false} />
                    ) : (
                      <div className="placeholder-item-image-bg mt-2">
                        <img
                          className="object-fit"
                          src={`${ENVIRONMENT.BASE_URL}assets/images/no-image.png`}
                          alt="placeholder"
                        />
                      </div>
                    )}
                  </div>
                </div>


                {/* customer photo */}
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <label className="w-100">Customer photo</label>
                    {currentItem &&
                      !customer_photo &&
                      showARButton &&
                      ![
                        ORDER_ITEM_STATUS.APPROVED.id,
                        ORDER_ITEM_STATUS.REJECTED.id,
                        ORDER_ITEM_STATUS.AGENT_REJECTED.id,
                        ORDER_ITEM_STATUS.ADMIN_REJECTED.id,
                      ].includes(currentItem.order_item_status) && (
                        <>
                          <ButtonDropdown>
                            <ButtonDropdown.Toggle className="btn btn-orange-primary-outline text-orange">
                              Upload
                            </ButtonDropdown.Toggle>
                            <ButtonDropdown.Menu>
                              <ButtonDropdown.Item
                                onClick={(e) => {
                                  setCameraUploadFile("customer_photo");
                                  dispatch(setShowCamera(true));
                                }}
                              >
                                Capture from camera
                              </ButtonDropdown.Item>
                              <ButtonDropdown.Item
                                onClick={handleFileCustomerPhotoUploadClick}
                              >
                                Upload from your computer
                              </ButtonDropdown.Item>
                            </ButtonDropdown.Menu>
                          </ButtonDropdown>
                          <input
                            accept={profileAccept}
                            ref={fileCustomerPhoto}
                            onChange={uploadFile}
                            type="file"
                            name="customer_photo"
                            className="d-none"
                          />
                        </>
                      )}
                    {!customer_photo ? (
                      <div className="placeholder-item-image-bg mt-2">
                        <img
                          className="object-fit"
                          src={`${ENVIRONMENT.BASE_URL}assets/images/no-image.png`}
                          alt="placeholder"
                        />
                      </div>
                    ) : (
                      <SingleFilePreview
                        cusClassName="mt-2"
                        showDeleteBtn={userData.user_role_id===DEFAULT_USER_ROLE.AGENT && currentItem?.order_item_status ===ORDER_ITEM_STATUS.NEW.id ?true:false}
                        file={customer_photo}
                        fieldName="customer_photo"
                      />
                    )}
                  </div>
                </div>
                {/* weight photo */}
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <label className="w-100">Weight photo</label>

                    {currentItem &&
                      !weight_photo &&
                      showARButton &&
                      ![
                        ORDER_ITEM_STATUS.APPROVED.id,
                        ORDER_ITEM_STATUS.REJECTED.id,
                        ORDER_ITEM_STATUS.AGENT_REJECTED.id,
                        ORDER_ITEM_STATUS.ADMIN_REJECTED.id,
                      ].includes(currentItem.order_item_status) && (
                        <>
                          <ButtonDropdown>
                            <ButtonDropdown.Toggle className="btn btn-orange-primary-outline text-orange">
                              Upload
                            </ButtonDropdown.Toggle>
                            <ButtonDropdown.Menu>
                              <ButtonDropdown.Item
                                onClick={(e) => {
                                  setCameraUploadFile("weight_photo");
                                  dispatch(setShowCamera(true));
                                }}
                              >
                                Capture from camera
                              </ButtonDropdown.Item>
                              <ButtonDropdown.Item
                                onClick={handleFileWeightPhotoUploadClick}
                              >
                                Upload from your computer
                              </ButtonDropdown.Item>
                            </ButtonDropdown.Menu>
                          </ButtonDropdown>
                          <input
                            accept={profileAccept}
                            ref={fileWeightPhoto}
                            onChange={uploadFile}
                            type="file"
                            name="weight_photo"
                            className="d-none"
                          />
                        </>
                      )}
                    {!weight_photo ? (
                      <div className="placeholder-item-image-bg mt-2">
                        <img
                          className="object-fit"
                          src={`${ENVIRONMENT.BASE_URL}assets/images/no-image.png`}
                          alt="placeholder"
                        />
                      </div>
                    ) : (
                      <SingleFilePreview
                        cusClassName="mt-2"
                        showDeleteBtn={userData.user_role_id===DEFAULT_USER_ROLE.AGENT && currentItem?.order_item_status ===ORDER_ITEM_STATUS.NEW.id ?true:false}
                        file={weight_photo}
                        fieldName="weight_photo"
                      />
                    )}
                  </div>
                </div>
                {/* purity photo */}
                <div className="col-md-3 col-12">
                  <div className="form-group">
                    <label className="w-100">Purity photo</label>
                    {currentItem &&
                      !purity_photo &&
                      showARButton &&
                      ![
                        ORDER_ITEM_STATUS.APPROVED.id,
                        ORDER_ITEM_STATUS.REJECTED.id,
                        ORDER_ITEM_STATUS.AGENT_REJECTED.id,
                        ORDER_ITEM_STATUS.ADMIN_REJECTED.id,
                      ].includes(currentItem.order_item_status) && (
                        <>
                          <ButtonDropdown>
                            <ButtonDropdown.Toggle className="btn btn-orange-primary-outline text-orange">
                              Upload
                            </ButtonDropdown.Toggle>
                            <ButtonDropdown.Menu>
                              <ButtonDropdown.Item
                                onClick={(e) => {
                                  setCameraUploadFile("purity_photo");
                                  dispatch(setShowCamera(true));
                                }}
                              >
                                Capture from camera
                              </ButtonDropdown.Item>
                              <ButtonDropdown.Item
                                onClick={handleFilePurityPhotoUploadClick}
                              >
                                Upload from your computer
                              </ButtonDropdown.Item>
                            </ButtonDropdown.Menu>
                          </ButtonDropdown>
                          <input
                            accept={profileAccept}
                            ref={filePurityPhoto}
                            onChange={uploadFile}
                            type="file"
                            name="purity_photo"
                            className="d-none"
                          />
                        </>
                      )}
                    {!purity_photo ? (
                      <div className="placeholder-item-image-bg mt-2">
                        <img
                          className="object-fit"
                          src={`${ENVIRONMENT.BASE_URL}assets/images/no-image.png`}
                          alt="placeholder"
                        />
                      </div>
                    ) : (
                      <SingleFilePreview
                        cusClassName="mt-2"
                        showDeleteBtn={userData.user_role_id===DEFAULT_USER_ROLE.AGENT && currentItem?.order_item_status ===ORDER_ITEM_STATUS.NEW.id ?true:false}
                        file={purity_photo}
                        fieldName="purity_photo"
                      />
                    )}
                  </div>
                </div>
                <div className="clearfix"></div>
                {/* support document */}
                {/* {supportingDocuments && Object.values(supportingDocuments).length  && ( */}
                <div className="col-12 mt-3">
                  <div className="form-group">
                    <label className="w-100">Support document</label>

                    {currentItem &&
                      ![
                        ORDER_ITEM_STATUS.APPROVED.id,
                        ORDER_ITEM_STATUS.REJECTED.id,
                        ORDER_ITEM_STATUS.AGENT_REJECTED.id,
                        ORDER_ITEM_STATUS.ADMIN_REJECTED.id,
                      ].includes(currentItem.order_item_status) && (
                        <>
                          <ButtonDropdown>
                            <ButtonDropdown.Toggle className="btn btn-orange-primary-outline text-orange">
                              Upload
                            </ButtonDropdown.Toggle>
                            <ButtonDropdown.Menu>
                              <ButtonDropdown.Item
                                onClick={(e) => {
                                  setCameraUploadFile(
                                    ATTACHMENT_TYPE.ORDER_ITEM_SUPPORT_DOCUMENT
                                  );
                                  dispatch(setShowCamera(true));
                                }}
                              >
                                Capture from camera
                              </ButtonDropdown.Item>
                              <ButtonDropdown.Item
                                onClick={handleFilefileSDUploadClick}
                              >
                                Upload from your computer
                              </ButtonDropdown.Item>
                            </ButtonDropdown.Menu>
                          </ButtonDropdown>
                          <input
                            accept={fileAccept}
                            type="file"
                            ref={fileSD}
                            className="d-none"
                            name={ATTACHMENT_TYPE.ORDER_ITEM_SUPPORT_DOCUMENT}
                            multiple
                            onChange={uploadFile}
                          />
                        </>
                      )}
                    <div className="row mt-2">
                      <MultipleFilePreview files={supportingDocuments} showDelete={true} />
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
              <h5 className="mt-5 mb-3">
                <strong>History</strong>
              </h5>
              <div className="row">
                {currentItem &&
                  currentItem.comments.map((comment, cmdInx) => (
                    <div className="col-12" key={cmdInx}>
                      <p className="m-0">
                        <strong>
                          {comment.first_name} {comment.middle_name}{" "}
                          {comment.last_name} ({comment.user_role_name})
                        </strong>
                      </p>
                      <p className="m-0">
                        {parseInt(comment.item_status) ===
                          ORDER_ITEM_AR_STATUS.REJECTED
                          ? "Rejected"
                          : "Approved"}
                      </p>
                      <p className="m-0">
                        <a
                          target="_blank"
                          href={`https://www.google.com/maps?q=${comment.latitude},${comment.longitude}`}
                        >
                          <IconWorkLocation /> Location
                        </a>
                      </p>
                      <p className="m-0">{comment.message}</p>
                      <p className="m-0">
                        {getDisplayDateTime(comment.created_at)}
                      </p>
                      <hr />
                    </div>
                  ))}
                {currentItem && currentItem.comments.length === 0 && (
                  <div className="col-12">No comments</div>
                )}
              </div>
            </div>
            {showARButton && (
              <div className="col-12 text-end" key={"tmd"}>
                <button
                  className="btn btn-approve-primary me-3"
                  type="button"
                  onClick={(e) => itemStatus(ORDER_ITEM_AR_STATUS.APPROVED)}
                >
                  Approve
                </button>
                <button
                  className="btn btn-reject-primary"
                  type="button"
                  onClick={(e) => itemStatus(ORDER_ITEM_AR_STATUS.REJECTED)}
                >
                  Reject
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <Dialog
        header="Comments"
        visible={showComment}
        style={{ width: isMobileScreen ? "100vw" : "25vw" }}
        onHide={(e) => {
          dispatch(setComment(""));
          dispatch(setShowComment(false));
        }}
        // maximizable
        className="comments-box"
      >
        <div className="form-group">
          <label>
            Status:{" "}
            {actionStatus && actionStatus === ORDER_ITEM_AR_STATUS.APPROVED
              ? "Approve"
              : "Reject"}
          </label>
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea
            onChange={handleChangeComment}
            name="comments"
            rows={4}
            className="form-control"
            value={comments}
          >
            {comments}
          </textarea>
          {errors.comments && (
            <span className="error-message">{errors.comments}</span>
          )}
        </div>
        <div className="form-group mt-4 text-end mb-3">
          <button
            disabled={disabledButton}
            className="btn  btn-purple-primary"
            type="button"
            onClick={updateOrderItemStatus}
          >
            {disabledButton ? <Spinner /> : null} Submit
          </button>
        </div>
      </Dialog>
      <CameraUpload onImgCallBack={handleCapture} />
      <FilePreview />
    </div>
  );
};

export default TradingItemApproval;
