import axios from 'axios';

import { setAccessRefreshToken, userLogout } from '../store/auth/reducer';
import { setDisabledButton, updateLoaderStatus } from '../store/general/reducer';
import { store } from '../store/store';

import { ENVIRONMENT, HTTP_STATUS_CODE, TOAST_TYPE } from '../config/constant';
import { showToast } from '../helper/toast-service-helper';

const api = axios.create({
    baseURL: ENVIRONMENT.API_URL
});
export default () => {
    return api;
}


api.interceptors.response.use((res) => {
    return res;
}, async (error) => {
    store.dispatch(setDisabledButton(false));
    let originalConfig = error.config;
    if (error?.code === "ERR_BAD_RESPONSE") {
        showToast(TOAST_TYPE.ERROR, 'Internal server error', 'top-right');
        return null;
    }
    
    if (error?.response?.data?.session_end && error.response.data.session_end === true) {
        showToast(TOAST_TYPE.ERROR, error.response.data.message, 'top-right');
        sessionStorage.clear();
        store.dispatch(updateLoaderStatus(false));
        store.dispatch(userLogout(false));
        return;
    }
    
    if (error.request.responseURL !== `${ENVIRONMENT.API_URL}service-one/auth/refresh`) {

        if (error.request.responseURL === `${ENVIRONMENT.BASE_URL}service-one/auth/reset-password` && error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
            showToast(TOAST_TYPE.ERROR, 'Reset password token expired', 'top-right');
            return;
        }
        
        if (error.response.status === HTTP_STATUS_CODE.UNAUTHORIZED) {

            
            const refToken =store.getState().auth.userRefreshToken;
            
            if (refToken) {
                
                const response = await api.get('service-one/auth/refresh', {
                    headers: {
                        Authorization: `Bearer ${refToken || ''}`,
                        Tokentype:'refresh'
                    }
                });

                if (response.status === HTTP_STATUS_CODE.OK) {
                    let tokens = response.data.data;
                    const _d = sessionStorage.getItem('_d');
                    let _dParse = _d ? JSON.parse(_d) : null;
                    if(_dParse){
                        const newData = {
                            ..._dParse,
                            access_token:tokens.access_token,
                            refresh_token: tokens.refresh_token
                        }
                        
                        store.dispatch(setAccessRefreshToken(newData));
                    }
                    originalConfig = {
                        ...originalConfig,
                        headers: {
                            ...originalConfig.headers,
                            Authorization: `Bearer ${tokens.access_token}`,
                        },
                    };

                } else {
                    sessionStorage.clear();
                    store.dispatch(updateLoaderStatus(false));
                    store.dispatch(userLogout(false));
                    // redirect to login and stop the axios api
                    return;
                }
            } else {
                
                sessionStorage.clear();
                store.dispatch(userLogout(false));
                if (error?.response?.data?.message) {
                    showToast(TOAST_TYPE.ERROR, error.response.data.message, 'top-right');
                }
                return;
            }
            // continue send currently request
            return api(originalConfig);

        }

    } else if (error.request.responseURL === `${ENVIRONMENT.API_URL}auth/refresh`) {
        
        clearSession();
    }
    
    return error;
});

const clearSession = () => {

}