//reducer
import { setfileDetails } from "./reducer";
import { updateLoaderStatus, setTableLoader, setDisabledButton, setDeleteFile } from '../general/reducer';


// others
import { HTTP_STATUS_CODE, TOAST_TYPE } from '../../config/constant';
import { showToast } from "../../helper/toast-service-helper";

//services
import fileService from '../../services/file'
import { handingApiErrors } from "../../helper/common-helper";

// export user create action
export const actionUpload = (data) => {
    return async (dispatch, getState) => {
        const response = await fileService.upload(data);
        dispatch(updateLoaderStatus(false));
        dispatch(setDisabledButton(false));
        if (response?.status === 200) {
            await dispatch(setfileDetails(response.data));
        } else {
            if (response?.data?.error) { showToast(TOAST_TYPE.ERROR, response.data.message, 'top-right'); }
        }
    }
}

// export delete by id action
export const actionDeleteBySlug = (slug, type, inx) => {
    return async (dispatch, getState) => {
        await dispatch(updateLoaderStatus(true));
        const response = await fileService.deleteBySlug(slug);
        await dispatch(updateLoaderStatus(false));

        if (response?.status !== HTTP_STATUS_CODE.OK) {
            handingApiErrors(response);
            return;
        }

        showToast(TOAST_TYPE.SUCCESS, "Attachment deleted successfully", 'top-right');

        switch (type) {
            case "customer_photo":
            case "customer_metal_photo":
            case "metal_photo":
            case "weight_photo":
            case "purity_photo":
                await dispatch(setDeleteFile({ inx, type }));
                break;
            default:
                
                await dispatch(setDeleteFile({ inx }));
                break;
        }
    }
}

export const actionFileById = (id) => {
    return async (dispatch, getState) => {
        const response = await fileService.getById(id);
        if (response?.status === 200) {
            await fileService.upload(response.data);
        }
    }
}