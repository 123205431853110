// Import files and libraries
import React, { useState, useEffect, useRef } from 'react';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from 'primereact/divider';
import { Dropdown } from 'primereact/dropdown';
import Accordion from 'react-bootstrap/Accordion';
import { Dropdown as ButtonDropdown } from 'react-bootstrap';


//others
import { IconBack, IconEdit, Spinner } from '../../../svg-icons';

// reducer
import { setFormModel, setFormData } from '../../../store/agent/reducer';
import { setDisabledButton, setProfileLoader, setShowCamera, setSupportingDocumentPlaceholder, updateLoaderStatus } from '../../../store/general/reducer';

// action
import { actionCreate, actionUpdate } from '../../../store/agent/action'


// custom component
import FilePreview from '../../../component/file-preview';
import { base64ToFile, checkFileUpload, checkFileUploadProfile, fileAccept, formatFileSize, getFileExtension, profileAccept } from '../../../helper/common-helper';
import { ATTACHMENT_TYPE, DEFAULT_USER_ROLE, ENVIRONMENT, PERMISSION_KEY, PERMISSION_PAGE_KEY, REGEXP, validatePermission } from '../../../config/constant';
import { getDisplayDateOnly } from '../../../helper/date-helper';

import MultipleFilePreview from '../../../component/multiple-file-upload';
import PermissionList from '../../../component/permission-list';
import FormStatus from '../../../component/form-status';
import { actionFileUpload } from '../../../store/general/action';
import { actionGetOrganizationList, actionGetOrgLocationList } from '../../../store/organization/action';
import CameraUpload from '../../../component/camera-upload';


const AgentFormModel = () => {
    // create a dispatch
    const dispatch = useDispatch();

    // create a state
    const [errors, setErrors] = useState({});
    const [editForm, setEditForm] = useState(false);
    const [cameraUploadFile, setCameraUploadFile] = useState("");

    const fileProfile = useRef(null);
    // Function to trigger file input click
    const handleFileProfileUploadClick = () => {
        fileProfile.current.click(); // Trigger the file input click
    };
    const fileSD = useRef(null);
    // Function to trigger file input click
    const handleFilefileSDUploadClick = () => {
        fileSD.current.click(); // Trigger the file input click
    };

    const { formData, updateId, formModel } = useSelector((state) => state.agent);
    const { disabledButton, profile, supportingDocuments, profileLoader } = useSelector((state) => state.general);
    const { organizationList, orgLocationList } = useSelector((state) => state.organization);

    const { formDefaultPermission, defaultUserPermission, userData } = useSelector((state) => state.auth);

    useEffect(() => {
        setErrors({});
        setEditForm(updateId ? false : true);
        setDefaultOrg();
        dispatch(actionGetOrganizationList());

    }, [formModel]);

    const setDefaultOrg = () => {
        if (parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION) {
            let data = {
                ...formData,
                organization_id: userData.id,
            };

            dispatch(actionGetOrgLocationList(userData.id));


            dispatch(setFormData(data));
        }
    }


    // form validation schema
    let schema = Yup.object().shape({
        first_name: Yup.string()
            .min(1, 'First name must be at least 1 character long')
            .max(50, 'First name must be at most 50 characters long')
            .matches(REGEXP.alphabetRegExp, 'First name can only contain alphabetic characters') // Allows letters and spaces only
            .matches(REGEXP.noConsecutiveSpaceRegExp, 'First name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
            .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
            .trim()
            .required('First name is required'),
        last_name: Yup.string()
            .min(1, 'last name must be at least 1 character long')
            .max(50, 'last name must be at most 50 characters long')
            .matches(REGEXP.alphabetRegExp, 'last name can only contain alphabetic characters') // Allows letters and spaces only
            .matches(REGEXP.noConsecutiveSpaceRegExp, 'last name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
            .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
            .trim()
            .required('Last name is required'),
        middle_name: Yup.string()
            .min(1, 'middle name must be at least 1 character long')
            .max(50, 'middle name must be at most 50 characters long')
            .matches(REGEXP.alphabetRegExp, 'middle name can only contain alphabetic characters') // Allows letters and spaces only
            .matches(REGEXP.noConsecutiveSpaceRegExp, 'middle name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
            .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
            .trim()
            .required('Middle name is required'),
        email: Yup.string()
            .max(50, 'Email must be at most 50 characters long')
            .matches(REGEXP.emailRegExp, 'Invalid email address')
            .required('Email is required'),
        phone_number: Yup.string()
            .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
            .max(15, 'Phone number must be at most 15 characters long')
            .min(7, 'Phone number must be at least 7 characters long')
            .required('Phone number is required'),
        national_id: Yup.string()
            .matches(REGEXP.numberRegExp, 'National ID is not valid')
            .min(5, 'National ID must be at least 5 character long')
            .max(10, 'National ID must be at most 10 characters long')
            .required('National ID is required'),
        designation: Yup.string()
            .max(50, 'Designation must be at most 50 characters long')
            .matches(/^(?!^[^a-zA-Z0-9]*$).*$/, 'Designation must contain at least one letter or number')
            .matches(/^(?!^[0-9]*$).*$/, 'Designation cannot be only numbers')
            .required('Designation is required'),
        organization_id: Yup.string()
            .required('Organization is required'),
        location_id: Yup.string()
            .required('Location is required'),
        gem_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Gem Target is not valid')
            .max(20, 'Monthly Gem Target must be at most 20 characters long')
            .required('Monthly Gem Target is required'),
        metal_target: Yup.string()
            .matches(REGEXP.decimalMumberRegExp, 'Monthly Metal Target is not valid')
            .max(20, 'Monthly Metal Target must be at most 20 characters long')
            .required('Monthly Metal Target is required'),
        work_experience: Yup.string()
            .matches(REGEXP.workExpRegExp, 'Work experience is not valid')
            .max(100, 'Work experience must be at most 100 characters long')
            .required('Work experience is required'),



    });

    // form modal close
    const closeModal = async () => {
        dispatch(setFormModel(false));
    };

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            let supporting_documents = [];
            if (Object.values(supportingDocuments).length > 0) {
                Object.values(supportingDocuments).map(doc => {
                    if (doc?.isNew) {
                        supporting_documents.push(doc.id);
                    }
                });
            }

            const finalData = {
                first_name: formData.first_name,
                middle_name: formData.middle_name,
                last_name: formData.last_name,
                national_id: formData.national_id,
                email: formData.email,
                phone_number: formData.phone_number,
                work_experience: formData.work_experience,
                designation: formData.designation,
                organization_id: formData.organization_id,
                location_id: formData.location_id,
                gem_target: formData.gem_target,
                metal_target: formData.metal_target,
                profile: profile !== null ? profile.file.id : null,
                active: formData.active,
                supporting_documents,
                permissions: Object.values(formDefaultPermission).filter(permission => permission !== 0)
            };

            if (updateId) { //check the form action update or create
                // update data with update id
                dispatch(actionUpdate(finalData, updateId));
            } else {
                // create form data
                dispatch(actionCreate(finalData));
            }
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        let data;

        if (name === 'date_of_formation') {
            value = getDisplayDateOnly(value);
        }
        if (name === 'organization_id') {
            dispatch(actionGetOrgLocationList(value));
        }

        data = {
            ...formData,
            [name]: value,
        };

        dispatch(setFormData(data));
        validateField(name, value);
    };

    const uploadFile = (e) => {
        const { name } = e.target;
        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        if (name === 'profile') {
            const ckf = checkFileUploadProfile(checkFile);
            if (ckf) {
                const formData = new FormData();
                formData.append('file', checkFile);
                dispatch(setProfileLoader(true));
                dispatch(actionFileUpload(formData));
            }
        } else {
            Array.from(e.target.files).forEach((file) => {
                const ckf = checkFileUpload(file);
                if (ckf) {
                    let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
                    let ext = getFileExtension(file.name);
                    dispatch(setSupportingDocumentPlaceholder({
                        inx, file: {
                            name: file.name,
                            type: file.type,
                            ext,
                            size: formatFileSize(file.size),
                            isNew: true
                        }
                    }));
                    const formSDData = new FormData();
                    formSDData.append('file', file); // Append the file to the FormData object

                    dispatch(actionFileUpload(formSDData, inx, name));
                }
            });
        }
    }

    const handleCapture = (e) => {

        const file = base64ToFile(e);
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        if (cameraUploadFile === 'profile') {
            dispatch(setProfileLoader(true));
            dispatch(actionFileUpload(formData));

        } else {
            let inx = 'file_' + Math.floor(Math.random() * (999 - 100 + 1)) + 100;
            let ext = 'png';
            dispatch(setSupportingDocumentPlaceholder({
                inx, file: {
                    name: file.name,
                    type: file.type,
                    ext,
                    size: formatFileSize(file.size),
                    isNew: true
                }
            }));
            const formSDData = new FormData();
            formSDData.append('file', file); // Append the file to the FormData object

            dispatch(actionFileUpload(formSDData, inx, ATTACHMENT_TYPE.ADMIN_SUPPORT_DOCUMENT));
        }

    }
    return (
        <>
            <form onSubmit={handleSubmit} noValidate>
                <div className='row'>
                    <div className="col-md-10 mb-3">
                        <div className='d-flex align-items-center'>
                            <IconBack
                                width={32}
                                height={32}
                                onIconClick={closeModal}
                                customClass='cursor-pointer'
                            />
                            <h3 className='flex-shrink-1 ms-2 mb-0'>{updateId ? 'Update Agent' : 'Create New Agent'}</h3>
                        </div>
                    </div>
                    {updateId && !editForm && validatePermission(defaultUserPermission, PERMISSION_PAGE_KEY.AGENT, PERMISSION_KEY.UPDATE) && (
                        <div className="col-md-2 mb-3 text-end">
                            <button
                                className="btn  btn-purple-primary me-3"
                                type='button'
                                onClick={e => setEditForm(true)}
                            >
                                <IconEdit customClass='text-white' /> Edit
                            </button>
                        </div>
                    )}
                </div>
                <div className='row'>
                    <div className="col-md-12">
                        <div className='col-md-auto d-flex'>
                            <div className='col-md-auto pb-3'>
                                {profile ? (
                                    <div className='placeholder-image-bg position-relative'>
                                        <img src={`data:${profile?.type};base64,${profile?.base64}`} />
                                        {profileLoader && (<span className="profile-loader">
                                            <Spinner width={36} height={36} />
                                        </span>
                                        )}
                                    </div>
                                ) : (
                                    <div className='placeholder-image-bg position-relative'>
                                        <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                        {profileLoader && (<span className="profile-loader">
                                            <Spinner width={36} height={36} />
                                        </span>
                                        )}
                                    </div>
                                )}
                            </div>
                            {editForm && (
                                <div className='col-md-auto align-self-center ms-3'>
                                    <input type="file" accept={profileAccept}  ref={fileProfile} name="profile" onChange={uploadFile} className='d-none' />
                                    <ButtonDropdown>
                                        <ButtonDropdown.Toggle className='btn btn-orange-primary'>
                                            Upload
                                        </ButtonDropdown.Toggle>
                                        <ButtonDropdown.Menu>
                                            <ButtonDropdown.Item onClick={e => {
                                                setCameraUploadFile('profile')
                                                dispatch(setShowCamera(true));
                                            }}>Capture from camera</ButtonDropdown.Item>
                                            <ButtonDropdown.Item onClick={handleFileProfileUploadClick}>

                                                Upload from your computer</ButtonDropdown.Item>
                                        </ButtonDropdown.Menu>
                                    </ButtonDropdown>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        <Accordion defaultActiveKey={['0', '1']} alwaysOpen className='custom-accordiant'>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><strong>Agent information</strong></Accordion.Header>
                                <Accordion.Body>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>First Name</label>
                                                <input type="text" className="form-control "
                                                    name="first_name"
                                                    disabled={!editForm}
                                                    value={formData.first_name}
                                                    onChange={handleChange}
                                                    placeholder='Enter the first name'
                                                />
                                                {errors.first_name && <span className="error-message">{errors.first_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Middle Name</label>
                                                <input type="text" className="form-control "
                                                    name="middle_name"
                                                    disabled={!editForm}
                                                    value={formData.middle_name}
                                                    onChange={handleChange}
                                                    placeholder='Enter the middle name'
                                                />
                                                {errors.middle_name && <span className="error-message">{errors.middle_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Last Name</label>
                                                <input type="text" className="form-control "
                                                    name="last_name"
                                                    disabled={!editForm}
                                                    value={formData.last_name}
                                                    onChange={handleChange}
                                                    placeholder='Enter the last name'
                                                />
                                                {errors.last_name && <span className="error-message">{errors.last_name}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>National Identity Number</label>
                                                <input type="text" className="form-control "
                                                    name="national_id"
                                                    disabled={!editForm}
                                                    value={formData.national_id}
                                                    onChange={handleChange}
                                                    placeholder='Enter the national ID'
                                                />
                                                {errors.national_id && <span className="error-message">{errors.national_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Email address</label>
                                                <input type="text" className="form-control "
                                                    name="email"
                                                    disabled={userData.user_role_id === DEFAULT_USER_ROLE.SUPER_ADMIN ? !editForm : updateId}
                                                    value={formData.email}
                                                    onChange={handleChange}
                                                    placeholder='Enter the email address'
                                                />
                                                {errors.email && <span className="error-message">{errors.email}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Phone Number</label>
                                                <input type="text" className="form-control "
                                                    name="phone_number"
                                                    disabled={userData.user_role_id === DEFAULT_USER_ROLE.SUPER_ADMIN ? !editForm : updateId}
                                                    value={formData.phone_number}
                                                    onChange={handleChange}
                                                    placeholder='Enter the phone number'
                                                />
                                                {errors.phone_number && <span className="error-message">{errors.phone_number}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Work Experience</label>
                                                <input type="text" className="form-control "
                                                    name="work_experience"
                                                    disabled={!editForm}
                                                    value={formData.work_experience}
                                                    onChange={handleChange}
                                                    placeholder='Enter the work experience'
                                                />
                                                {errors.work_experience && <span className="error-message">{errors.work_experience}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Designation</label>
                                                <input type="text" className="form-control "
                                                    name="designation"
                                                    disabled={!editForm}
                                                    value={formData.designation}
                                                    onChange={handleChange}
                                                    placeholder='Enter the designation'
                                                />
                                                {errors.designation && <span className="error-message">{errors.designation}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Organisation</label>
                                                {
                                                    parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.ORGANIZATION ? (
                                                        <Dropdown
                                                            disabled={true}
                                                            value={formData.organization_id}
                                                            // onChange={handleChange}
                                                            options={organizationList}
                                                            optionLabel="organization_name"
                                                            optionValue="id"
                                                            placeholder="Select a organisation"
                                                            className="w-100 custom-single-dropdown"
                                                            name='organization_id'
                                                        />
                                                    ) : (
                                                        <Dropdown
                                                            disabled={!editForm}
                                                            value={formData.organization_id}
                                                            onChange={handleChange}
                                                            options={organizationList}
                                                            optionLabel="organization_name"
                                                            optionValue="id"
                                                            placeholder="Select a organisation"
                                                            className="w-100 custom-single-dropdown"
                                                            name='organization_id'
                                                        />
                                                    )
                                                }


                                                {errors.organization_id && <span className="error-message">{errors.organization_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Location</label>
                                                <Dropdown
                                                    disabled={!editForm}
                                                    value={formData.location_id}
                                                    onChange={handleChange}
                                                    options={orgLocationList}
                                                    optionLabel="location_name"
                                                    optionValue="id"
                                                    placeholder="Select a location"
                                                    className="w-100 custom-single-dropdown"
                                                    name='location_id'
                                                />
                                                {errors.location_id && <span className="error-message">{errors.location_id}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Gem Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="gem_target"
                                                        disabled={!editForm}
                                                        value={formData.gem_target}
                                                        onChange={handleChange}
                                                        placeholder='Enter the monthly gem target'
                                                    />
                                                    <span className="input-group-text" id="gem_targe">Carat</span>
                                                </div>

                                                {errors.gem_target && <span className="error-message">{errors.gem_target}</span>}
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label>Monthly Metal Target</label>
                                                <div className="input-group mb-3">
                                                    <input type="text" className="form-control "
                                                        name="metal_target"
                                                        disabled={!editForm}
                                                        value={formData.metal_target}
                                                        onChange={handleChange}
                                                        placeholder='Enter the monthly metal target'
                                                    />
                                                    <span className="input-group-text" id="metal_targe">Gm</span>
                                                </div>

                                                {errors.metal_target && <span className="error-message">{errors.metal_target}</span>}
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group mb-3">
                                                <label className='w-100'>Supporting documents</label>
                                                {editForm && (
                                                    <>
                                                        <ButtonDropdown>
                                                            <ButtonDropdown.Toggle className='btn btn-orange-primary-outline text-orange'>
                                                                Upload
                                                            </ButtonDropdown.Toggle>
                                                            <ButtonDropdown.Menu>
                                                                <ButtonDropdown.Item onClick={e => {
                                                                    setCameraUploadFile(ATTACHMENT_TYPE.AGENT_SUPPORT_DOCUMENT)
                                                                    dispatch(setShowCamera(true));
                                                                }}>Capture from camera</ButtonDropdown.Item>
                                                                <ButtonDropdown.Item onClick={handleFilefileSDUploadClick}>

                                                                    Upload from your computer</ButtonDropdown.Item>
                                                            </ButtonDropdown.Menu>
                                                        </ButtonDropdown>

                                                        <input type="file" accept={fileAccept} ref={fileSD} className='d-none' name={ATTACHMENT_TYPE.AGENT_SUPPORT_DOCUMENT} multiple onChange={uploadFile} />
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <MultipleFilePreview files={supportingDocuments} showDelete={editForm} />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><strong>Permission</strong></Accordion.Header>
                                <Accordion.Body>
                                    {parseInt(userData.user_role_id) === DEFAULT_USER_ROLE.SUPER_ADMIN ? (
                                        <PermissionList editForm={editForm} />
                                    ) : (
                                        <PermissionList editForm={false} />
                                    )}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className='col-12 mb-3'>
                        <FormStatus
                            onStatusClick={handleChange}
                            active={formData.active}
                            id={updateId}
                            editForm={editForm}
                        />
                    </div>
                    <Divider />
                    <div className="col-12 text-end">
                        <div className="form-group mb-3">
                            {editForm && (
                                <>
                                    <button
                                        className="btn  btn-purple-primary-outline me-3"
                                        type='button'
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        disabled={disabledButton}
                                        className="btn  btn-purple-primary"
                                        type='submit'
                                    >
                                        {disabledButton ? <Spinner /> : null} {updateId ? 'Update' : 'Create'}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </form>
            <CameraUpload onImgCallBack={handleCapture} />
            <FilePreview />
        </>
    );
};


export default AgentFormModel;
