import React, { useEffect } from 'react';
import { Outlet, Navigate, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DEFAULT_USER_ROLE } from '../../config/constant';

const PublicRoutes = ({ page = null }) => {
    const { isAuth, userData } = useSelector(state => state.auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (!isAuth && page !=='reset-password') {
            navigate('/');
        }

    }, [isAuth])
    return isAuth ? userData.user_role_id === DEFAULT_USER_ROLE.CUSTOMER ? <Navigate to='/dashboard' /> : <Navigate to='/portal/trading' /> : <Outlet />
    // }

    // if(isAuth){
    //     if(userData.user_role_id === DEFAULT_USER_ROLE.CUSTOMER){

    //     }else{

    //     }
    // }
}

export default PublicRoutes;