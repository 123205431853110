import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { actionGetLocationList } from '../../../store/location/action';
import { ENVIRONMENT, MARITAL_STATUS_LIST, REGEXP, TOAST_TYPE } from '../../../config/constant';
import { setDisabledButton, setShowCamera, updateLoaderStatus, updatePageTitle } from '../../../store/general/reducer';
import { actionCreate, actionGetById, actionUpdate, actionValidateNationalId } from '../../../store/customer/action';

import { setFormData, setShowRegisterationSuccessMsg } from '../../../store/customer/reducer';
import { showToast } from '../../../helper/toast-service-helper';
import { actionFileUpload } from '../../../store/general/action';
import { Dropdown } from 'primereact/dropdown';
import { IconMsgSuccessCheckMark, Spinner } from '../../../svg-icons';
import { useNavigate } from 'react-router-dom';
import { getDatabaseDateOnly, getDatabaseDateTime, getDisplayDateOnly } from '../../../helper/date-helper';
import { Dropdown as ButtonDropdown } from 'react-bootstrap';
import { base64ToFile, checkFileUploadProfile, isMobileScreen, profileAccept } from '../../../helper/common-helper';
import CameraUpload from '../../../component/camera-upload';
import { IconBack } from '../../../svg-icons';

const CustomerProfile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    // create a state
    const [errors, setErrors] = useState({});
    const [cameraUploadFile, setCameraUploadFile] = useState("");

    const fileProfile = useRef(null);
    // Function to trigger file input click
    const handleFileProfileUploadClick = () => {
        fileProfile.current.click(); // Trigger the file input click
    };

    const { userData } = useSelector((state) => state.auth);
    const { formData } = useSelector((state) => state.customer);
    const { locationList } = useSelector((state) => state.location);
    const { disabledButton, profile } = useSelector((state) => state.general);

    useEffect(() => {
        setErrors({});
        dispatch(actionGetLocationList());
        dispatch(updatePageTitle("Profile"));
        dispatch(actionGetById(userData.id))
    }, []);


    // form validation schema
    let schema = Yup.object().shape({
        // first_name: Yup.string()
        //     .max(50, 'First name must be at most 50 characters long')
        //     .required('First name is required'),
        // last_name: Yup.string()
        //     .max(50, 'Last name must be at most 50 characters long')
        //     .required('Last name is required'),
        // middle_name: Yup.string()
        //     .max(50, 'Middle name must be at most 50 characters long')
        //     .required('Middle name is required'),
        // email: Yup.string()
        //     .max(255, 'Email must be at most 255 characters long')
        //     .matches(REGEXP.emailRegExp, 'Invalid email address')
        //     .required('Email is required'),
        // phone_number: Yup.string()
        //     .matches(REGEXP.phoneNumberRegExp, 'Phone number is not valid')
        //     .max(20, 'Phone number must be at most 20 characters long')
        //     .required('Phone number is required'),
        // national_id:
        //     Yup.string()
        //         .matches(REGEXP.numberRegExp, 'National ID is not valid')
        //         .max(20, 'National ID must be at most 20 characters long')
        //         .required('National ID is required'),
        marital_status:
            Yup.string()
                .required('Marital Status is required'),
        occupation: Yup.string()
                .min(1, 'occupation must be at least 1 character long')
                .max(50, 'occupation must be at most 50 characters long')
                .matches(REGEXP.alphabetRegExp, 'occupation can only contain alphabetic characters') // Allows letters and spaces only
                .matches(REGEXP.noConsecutiveSpaceRegExp, 'occupation cannot contain consecutive spaces') // Prevents multiple consecutive spaces
                .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
                .trim()
                  .required("Occupation is required"),
        nominee_name: Yup.string()
                .min(1, 'Next of Kin name must be at least 1 character long')
                .max(50, 'Next of Kin  name must be at most 50 characters long')
                .matches(REGEXP.alphabetRegExp, 'Next of Kin  name can only contain alphabetic characters') // Allows letters and spaces only
                .matches(REGEXP.noConsecutiveSpaceRegExp, 'Next of Kin  name cannot contain consecutive spaces') // Prevents multiple consecutive spaces
                .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
                .trim()
                .required('Next of Kin name is required'),
        nominee_relationship: Yup.string()
                .min(1, 'Next of Kin relationship must be at least 1 character long')
                .max(50, 'Next of Kin  relationship must be at most 50 characters long')
                .matches(REGEXP.alphabetRegExp, 'Next of Kin  relationship can only contain alphabetic characters') // Allows letters       and spaces only
                .matches(REGEXP.noConsecutiveSpaceRegExp, 'Next of Kin  relationship cannot contain consecutive spaces') // Prevents multiple consecutive spaces
                .matches(REGEXP.nonEmptyStringRegExp, 'Comments cannot contain only spaces')
                .required("Next of Kin Relationship is required"),
        nominee_phone_number: Yup.string()
                  .matches(REGEXP.phoneNumberRegExp, "Phone number is not valid")
                  .max(15, "Phone number must be at most 15 characters long")
                  .min(7, "Phone number must be at least 7 characters long")
                  .required("Next of Kin Phone number is required"),
        address:
            Yup.string()
            .trim()
            .max(255, 'Address must be at most 255 characters long')
            .test('not-only-numbers', 'Address cannot be only numbers', (value) => {
                return !/^[0-9]+$/.test(value); // Ensure the address is not only numbers
            })
            .test('contains-letter-or-number', 'Address must contain at least one letter', (value) => {
                return /[a-zA-Z]/.test(value); // Ensure there is at least one letter or number
            })
                .required('Address is required'),
        // remarks:
        //     Yup.string()
        //         .max(255, 'Remarks must be at most 255 characters long')
        //         .required('Remarks is required'),
        location_id:
            Yup.string()
                .required('Location is required'),
        // organization_id:
        //     Yup.string()
        //         .required('Organization is required'),
        // agent_id:
        //     Yup.string(),
        // isAgreed: Yup.boolean()
        //     .oneOf([true], 'You must accept the terms and conditions')
        //     .required('Agent is required')

    });

    //validate the fields
    const validateField = async (name, value) => {
        try {
            await Yup.reach(schema, name).validate(value);
            setErrors({ ...errors, [name]: '' });
        } catch (validationError) {
            setErrors({ ...errors, [name]: validationError.message });
        }
    };

    //validate the form
    const validateForm = async () => {
        try {
            await schema.validate(formData, { abortEarly: false });
            setErrors({});
            return true;
        } catch (validationErrors) {
            const newErrors = validationErrors.inner.reduce((acc, err) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            setErrors(newErrors);
            return false;
        }
    };

    //handling the form submition
    const handleSubmit = async (e) => {

        // prevent the form submission
        e.preventDefault();

        // get the form status
        const isValid = await validateForm();

        if (isValid) { // check the form is valid or invalid
            dispatch(setDisabledButton(true));
            dispatch(updateLoaderStatus(true));

            const finalData = {
                first_name: formData.first_name,
                middle_name: formData.middle_name,
                last_name: formData.last_name,
                national_id: formData.national_id,
                email: formData.email,
                phone_number: formData.phone_number,
                citizen_ship: formData.citizen_ship,
                cladfy_request_id: formData.cladfy_request_id,
                serial_number: formData.serial_number,
                dob: formData.dob,
                gender: formData.gender,
                marital_status: formData.marital_status,
                occupation: formData.occupation,
                nominee_name: formData.nominee_name,
                nominee_relationship: formData.nominee_relationship,
                nominee_phone_number: formData.nominee_phone_number,
                address: formData.address,
                remarks: formData.remarks,
                location_id: formData.location_id,
                profile: profile !== null ? profile.file.id : null,
                active: formData.active,
                organization_id: formData.organization_id,
                agent_id: formData.agent_id,
                verified_phone_number: formData.verified_phone_number?formData.verified_phone_number:0,
            };
            // create form data
            dispatch(actionUpdate(finalData, userData.id, 'customer'));
        } else {
            console.log('Form has errors:', errors);
        }
    };

    // handling the input changes
    const handleChange = async (e) => {
        let { name, value } = e.target;

        if (name === 'dob') {
            value = getDisplayDateOnly(value);
        }
        if (name === 'isAgreed') {
            value = e.target.checked;
        }

        let data = {
            ...formData,
            [name]: value,
        };
        if (name === 'location_id') {

            const currentLocationData = locationList.filter(loc => loc.id === value)[0];

            data = {
                ...formData,
                [name]: value,
                organization_id: currentLocationData.organization_id,
                agent_id: currentLocationData.agent_id
            };
        }

        dispatch(setFormData(data));
        validateField(name, value);
    };

    const uploadFile = (e) => {
        const { name } = e.target;
        const checkFile = e.target.files[0]; // Get the selected file
        if (!checkFile) return;
        const ckf = checkFileUploadProfile(checkFile);
        if (ckf) {
            const formData = new FormData();
            formData.append('file', checkFile);
            dispatch(actionFileUpload(formData));

        }
    }

    const handleCapture = (e) => {

        const file = base64ToFile(e);
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);

        dispatch(actionFileUpload(formData));
    }
    const handleBackClick = () => {
        navigate('/dashboard'); // Navigates back to the dashboard
    };
    return (
        <>
            <div className='row'>
                <div className='col-md-2'></div>
                <div className={`col-md-8 col-12 ${isMobileScreen ? '':'p-5'}`}>
                    <div className='d-flex align-items-center pb-4'>
                        <div
                            className="me-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Go to dashboard"
                        >
                            <IconBack
                                width={42}
                                height={42}
                                onIconClick={handleBackClick}
                                customClass="cursor-pointer"
                            />
                        </div>
                        <h5 className='ms-2 flex-shrink-1 mb-0'><strong>My Profile</strong></h5>
                    </div>

                    <form onSubmit={handleSubmit} noValidate>
                        <div className='row'>
                            <div className='col-md-2'>
                                {profile ? (
                                    <div className='placeholder-image-bg'>
                                        <img src={`data:${profile?.type};base64,${profile?.base64}`} />
                                    </div>
                                ) : (
                                    <div className='placeholder-image-bg'>
                                        <img src={`${ENVIRONMENT.BASE_URL}assets/images/profile.png`} />
                                    </div>
                                )}
                            </div>
                            <div className='col-md-2 align-self-center'>
                                <input accept={profileAccept} type="file" ref={fileProfile} name="profile" onChange={uploadFile} className='d-none' />
                                <ButtonDropdown>
                                    <ButtonDropdown.Toggle className='btn btn-orange-primary'>
                                        Upload
                                    </ButtonDropdown.Toggle>
                                    <ButtonDropdown.Menu>
                                        <ButtonDropdown.Item onClick={e => {
                                            setCameraUploadFile('profile')
                                            dispatch(setShowCamera(true));
                                        }}>Capture from camera</ButtonDropdown.Item>
                                        <ButtonDropdown.Item onClick={handleFileProfileUploadClick}>

                                            Upload from your computer</ButtonDropdown.Item>
                                    </ButtonDropdown.Menu>
                                </ButtonDropdown>
                            </div>

                            <div className="col-12 mt-3">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>National Identity Number</label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control "
                                                    name="national_id"
                                                    disabled={true}
                                                    value={formData.national_id}
                                                    placeholder='Enter the National ID'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Serial Number</label>
                                            <input
                                                disabled={true}
                                                type="text"
                                                className="form-control "
                                                name="serial_number"
                                                placeholder='Enter the serial number'
                                                value={formData.serial_number}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>First Name</label>
                                            <input type="text" className="form-control "
                                                disabled={true}
                                                name="first_name"
                                                placeholder='Enter the first name'
                                                value={formData.first_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Middle Name</label>
                                            <input type="text" className="form-control "
                                                disabled={true}
                                                name="middle_name"
                                                placeholder='Enter the middle name'
                                                value={formData.middle_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Last Name</label>
                                            <input type="text" className="form-control "
                                                disabled={true}
                                                name="last_name"
                                                placeholder='Enter the last name'
                                                value={formData.last_name}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Gender</label>
                                            <input type="text" className="form-control "
                                                disabled={true}
                                                name="gender"
                                                placeholder='Enter the gender'
                                                value={formData.gender}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Date of Birth</label>
                                            <input type="text" className="form-control "
                                                disabled={true}
                                                name="dob"
                                                placeholder='Enter the date of birth'
                                                value={getDisplayDateOnly(formData.dob)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Citizenship</label>
                                            <input type="text" className="form-control "
                                                disabled={true}
                                                name="citizen_ship"
                                                placeholder='Enter the citizenship'
                                                value={formData.citizen_ship}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Marital Status</label>
                                            <Dropdown
                                                value={formData.marital_status}
                                                onChange={handleChange}
                                                options={MARITAL_STATUS_LIST}
                                                optionLabel="name"
                                                optionValue="id"
                                                placeholder="Select the marital status"
                                                className="w-100 custom-single-dropdown"
                                                name='marital_status'
                                            />

                                            {errors.marital_status && <span className="error-message">{errors.marital_status}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Mining Location</label>
                                            <Dropdown
                                                value={formData.location_id}
                                                onChange={handleChange}
                                                options={locationList}
                                                optionLabel="location_name"
                                                optionValue="id"
                                                placeholder="Select the location"
                                                className="w-100 custom-single-dropdown"
                                                name='location_id'
                                            />
                                            {errors.location_id && <span className="error-message">{errors.location_id}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Email address</label>
                                            <input type="text" className="form-control "
                                                name="email"
                                                placeholder='Enter the email address'
                                                value={formData.email}
                                                disabled={true}
                                            />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>M-Pesa Phone Number</label>
                                            <input type="text" className="form-control "
                                                name="phone_number"
                                                placeholder='Enter the phone number'
                                                value={formData.phone_number}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Occupation</label>
                                            <input type="text" className="form-control "
                                                name="occupation"
                                                placeholder='Enter the occupation'
                                                value={formData.occupation}
                                                onChange={handleChange}
                                            />
                                            {errors.occupation && <span className="error-message">{errors.occupation}</span>}
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Next of Kin Name</label>
                                            <input type="text" className="form-control "
                                                name="nominee_name"
                                                placeholder='Enter the next of kin name'
                                                value={formData.nominee_name}
                                                onChange={handleChange}
                                            />
                                            {errors.nominee_name && <span className="error-message">{errors.nominee_name}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Next of Kin Relationship</label>
                                            <input type="text" className="form-control "
                                                name="nominee_relationship"
                                                placeholder='Enter the next of kin relationship'
                                                value={formData.nominee_relationship}
                                                onChange={handleChange}
                                            />
                                            {errors.nominee_relationship && <span className="error-message">{errors.nominee_relationship}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Next of Kin Phone Number</label>
                                            <input type="text" className="form-control "
                                                name="nominee_phone_number"
                                                placeholder='Enter the next of kin phone number'
                                                value={formData.nominee_phone_number}
                                                onChange={handleChange}
                                            />
                                            {errors.nominee_phone_number && <span className="error-message">{errors.nominee_phone_number}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3">
                                            <label>Address</label>
                                            <textarea className="form-control "
                                                name="address"
                                                placeholder={`Address Line 1\nAddress Line 2\nCity\nState\nCountry\nPincode`}
                                                value={formData.address}
                                                onChange={handleChange}
                                                rows={6}
                                            />
                                            {errors.address && <span className="error-message">{errors.address}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="form-group mb-3">
                                    <button
                                        disabled={disabledButton}
                                        className="btn btn-orange-primary"
                                        type='submit'
                                    >
                                        {disabledButton ? <Spinner /> : null} Update
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>
                <div className='col-md-2'></div>
            </div>
            <CameraUpload onImgCallBack={handleCapture} />
        </>

    );
};
export default CustomerProfile;