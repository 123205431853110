
export const ENVIRONMENT = {
  // FOR:'DEV',
  FOR:'PROD',
  // BASE_URL: 'http://localhost:2000/', 
  // API_URL: 'http://localhost:2001/'
  // BASE_URL: 'http://192.168.1.21:2000/',
  // API_URL: 'http://192.168.1.21:2001/'
  // BASE_URL : 'https://ct.rayi.in/',
  // API_URL: 'https://ct.rayi.in/',
  // BASE_URL : 'https://dev.madinichapaa.com/',
  // API_URL: 'https://dev.madinichapaa.com/'
  BASE_URL : 'https://app.madinichapaa.com/',
  API_URL: 'https://app.madinichapaa.com/'
}

export const TOAST_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warn",
  INFO: "info"
}

export const ORDER_ITEM_STATUS = {
  NEW: { id: 1, text: "New" },
  AGENT_REJECTED: { id: 2, text: "Agent Rejected" },
  WAITING_ADMIN_REVIEW: { id: 3, text: "Waiting for admin review" },
  ADMIN_REJECTED: { id: 4, text: "Admin Rejected" },
  WAITING_SUPER_ADMIN_REVIEW: { id: 5, text: "Waiting for super admin review" },
  REJECTED: { id: 6, text: "Rejected" },
  APPROVED: { id: 7, text: "Approved" }
}
export const ORDER_STATUS = {
  NEW: { id: 1, text: "New" },
  INPROGRESS: { id: 2, text: "Inprogress" },
  REJECTED: { id: 3, text: "Rejected" },
  DISBURSAL: { id: 4, text: "Waiting for Disbursal" },
  SETTLED: { id: 5, text: "Settled" }
}
export const ORDER_ITEM_AR_STATUS = {
  REVIEW: 1,
  APPROVED: 2,
  REJECTED: 3
}


export const SIDEBAR_OPTION = {
  FULL_SIDEBAR: "active",
  ICON_SIDEBAR: "icon-sidebar",
  CLOSED: "",
  MOBILE: "mobile-menu"
}
export const RECORD_STATUS = {
  ACTIVE: { id: 1, name: 'Active' },
  INACTIVE: { id: 2, name: 'Inactive' }
};

export const PRODUCT_CERTIFICATION_LIST = [
  { id: 1, name: 'Yes' },
  { id: 0, name: 'No' }
];
export const PRODUCT_CERTIFICATION = {

  YES: { id: 1, name: 'Yes' },
  NO: { id: 0, name: 'No' }
};

export const MARITAL_STATUS_LIST = [
  { id: 'Married', name: "Married" },
  { id: 'Unmarried', name: "Unmarried" },
  { id: 'Divorced', name: "Divorced" },
  { id: 'Widow', name: "Widow" }
];

export const DEFAULT_USER_ROLE = {
  SUPER_ADMIN: 1,
  ADMIN: 2,
  AGENT: 3,
  ORGANIZATION: 4,
  CUSTOMER: 5,
};
export const HTTP_STATUS_CODE = {
  CREATE: 200,
  UPDATE: 200,
  DELETE: 200,
  GET: 200,
  OK: 200,
  UNAUTHORIZED: 401,
  PAGE_NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  VALIDATION_ERROR: 400
}


export const TABLES = {
  USER: 'user',
  TRADE: 'trade',
  ITEMS: 'items',
}
export const ROLE_LIST = [
  { id: DEFAULT_USER_ROLE.ADMIN, name: "Admin" },
  // { id: DEFAULT_USER_ROLE.SUPER_ADMIN, name:"Super admin"}
];

export const ENCRYPTION_KEY = 'a1b2c3d4e5f6g7h8i9j0k1l2m3n4o5p6';
export const IV = '1234567890123456';

export const ATTACHMENT_TYPE = {
  ADMIN_PROFILE: "admin-profile",
  ADMIN_SUPPORT_DOCUMENT: "admin-support_document",
  AGENT_PROFILE: "agent-profile",
  AGENT_SUPPORT_DOCUMENT: "agent-support_document",
  ORG_PROFILE: "org-profile",
  ORG_SUPPORT_DOCUMENT: "org-support_document",
  CUSTOMER_PROFILE: "customer-profile",
  CUSTOMER_SUPPORT_DOCUMENT: "customer-support_document",
  ORDER_ITEM_CUSTOMER: "order-item-customer",
  ORDER_ITEM_METAL: "order-item-metal",
  ORDER_ITEM_CUSTOMER_METAL: "order-item-customer-metal",
  ORDER_ITEM_WEIGHT: "order-item-weight",
  ORDER_ITEM_PURITY: "order-item-purity",
  ORDER_ITEM_SUPPORT_DOCUMENT: "order-item-support_document"
};

export const PERMISSION_KEY = {
  READ: "read",
  ADD: "add",
  ADD_ONLY: "add-only",
  UPDATE: "update",
  DISBURSAL: "disbursal"
};
export const PERMISSION_PAGE_KEY = {
  CUSTOMER: "customer",
  TRADING: "trading",
  LOCATION: "location",
  PRODUCT: "product",
  ORGANIZATION: "organisation",
  AGENT: "agent",
  ADMIN: "admin",
};


export const validatePermission = (defaultUserPermission, pageKey, pageAction) => {
  return defaultUserPermission && defaultUserPermission[pageKey] && defaultUserPermission[pageKey][pageAction];
}

export const REGEXP = {
  phoneNumberRegExp: /^[0-9]+$/,
  numberRegExp: /^[0-9]+$/,
  decimalMumberRegExp: /^[0-9]+(\.[0-9]{1,2})?$/,
  emailRegExp: /^[A-Za-z0-9]+(?:[._%+-][A-Za-z0-9]+)*@[A-Za-z0-9-]+\.[A-Za-z]{2,}$/,
  passwordRegExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  percentageRegExp: /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,2})?)$/,
  workExpRegExp: /^(100(\.0{1,2})?|[0-9]{1,2}(\.[0-9]{1,1})?)$/,
  nonEmptyStringRegExp: /\S+/,
  alphabetRegExp:/^[A-Za-z ]+$/,
  noConsecutiveSpaceRegExp:/^(?!.*  ).*$/
}
export const ALLOWED_FILE_TYPE = {
  accept: ".jpg, .jpeg, .png, .gif, .docx, .pdf"
}