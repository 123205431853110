// import io from 'socket.io-client';
import { HTTP_STATUS_CODE, TOAST_TYPE } from '../config/constant';
import { store } from '../store/store';

import { showToast } from './toast-service-helper';


// axios header configuration with user token
export const axiosOptions = async () => {

    // get the user token
    
    const {latitude,longitude, userToken,sessionId} = await store.getState().auth;

    return {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`,
            'X-Latitude': `${latitude}`,
            'X-Longitude': `${longitude}`,
            'Currentsession': `${sessionId}`,
        }
    }
}
// axios header configuration with user token & formData
export const axiosOptionsForFormData = async () => {

    // get the user token
    const {latitude,longitude, userToken,sessionId} = await store.getState().auth;

    return {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${userToken}`,
            'X-Latitude': `${latitude}`,
            'X-Longitude': `${longitude}`,
            'Currentsession': `${sessionId}`,
        }
    }
}
export const getFileExtension = (fileName) => {
    return (fileName.slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2)).toUpperCase();
}

// Deep copy
export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));

export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Byte';
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const truncateFileName = (fileName, maxLength = 10) => {
    const parts = fileName.split('.');
    if (parts.length < 2) return fileName; // No extension found

    const extension = parts.pop(); // Get the extension
    const baseName = parts.join('.'); // Get the base name without extension

    if (baseName.length > maxLength) {
        const truncatedBaseName = baseName.substring(0, maxLength - 3) + '...'; // Truncate and add ellipsis
        return `${truncatedBaseName}.${extension}`;
    }

    return `${baseName}.${extension}`;
};


export const validateFileSize = (file, maxSizeMB = 5) => {
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > maxSizeMB) {
        // throw new Error(ERROR_MESSAGE.FILE_SIZE);
    }
};

export const fixedTwoDigit = (value) => {
    return typeof value === 'string' ? parseFloat(value).toFixed(2) : value.toFixed(2);
}
export const roundUpValue = (value) => {
    return Math.round(value);
}


//remove .00
export const validateFloatNumber = (amount) => {
    if (typeof amount === 'string' || typeof amount === 'number') {
        var num = parseFloat(amount);

        // Check if the number is an integer (i.e., no decimal part or .00)
        if (Number.isInteger(num)) {
            // If it's an integer, return the number without decimal part
            return num;
        } else {
            // If it's not an integer, return the original amount
            return amount;
        }
    } else {
        return amount;
    }
}

// indian currency format
export const currencyFormatValue = (amount, roundUp = false) => {
    if (roundUp) {
        amount = Math.round(amount);
    }
    return new Intl.NumberFormat('en-KE', {
        // style: 'currency',
        // currency: 'KES',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
};


export const handingApiErrors = (error) => {
    if (error?.response?.status === HTTP_STATUS_CODE.VALIDATION_ERROR) {       
        if (typeof error.response.data.message === "string" && error.response.data.message !=="") {
            showToast(TOAST_TYPE.ERROR, error.response.data.message, 'top-right');
            // return;
        } else if(error.response.data.message === "object") {
            Object.values(error.response.data.message)
                .map((e, index) => {
                    showToast(TOAST_TYPE.ERROR, e, 'top-right');
                });
        }
        if (typeof error.response.data.error_messages === "object") {
            Object.values(error.response.data.error_messages)
                .map((e, index) => {
                    showToast(TOAST_TYPE.ERROR, e, 'top-right');
                });
            // return;
        }

    }
}

// convert base64 image to blob file
export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}

export const base64ToFile = (base64String, filename = "Captured Image.png") => {
    const byteString = atob(base64String.split(',')[1]); // Decode base64 string
    const mimeString = base64String.split(',')[0].split(':')[1].split(';')[0]; // Get the mime type

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        byteNumbers[i] = byteString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: mimeString }); // Create a Blob

    return new File([file], filename, { type: mimeString });
}

export const checkFileUploadProfile = (checkFile) => {
    // Allowed file types
    const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];

    // File size limit (example: 5MB = 5 * 1024 * 1024 bytes)
    const maxSize = 10 * 1024 * 1024;

    // Check file type
    if (!allowedTypes.includes(checkFile.type)) {
        showToast(TOAST_TYPE.ERROR, 'Invalid file type. Allowed types are:  JPG, JPEG, PNG.', 'top-right');
        return false;
    }

    // Check file size
    if (checkFile.size > maxSize) {
        showToast(TOAST_TYPE.ERROR, 'File size exceeds 10MB. ' + checkFile.name);
        return false;
    }
    return true;
}
export const checkFileUpload = (checkFile) => {
    // Allowed file types
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

    // File size limit (example: 5MB = 5 * 1024 * 1024 bytes)
    const maxSize = 10 * 1024 * 1024;

    // Check file type
    if (!allowedTypes.includes(checkFile.type)) {
        showToast(TOAST_TYPE.ERROR, 'Invalid file type. Allowed types are: PDF, JPG, JPEG, PNG, DOC, DOCX.', 'top-right');
        return false;
    }

    // Check file size
    if (checkFile.size >= maxSize) {
        showToast(TOAST_TYPE.ERROR, 'File size exceeds 10MB. ' + checkFile.name);
        return false;
    }
    return true;
}
export const profileAccept = ".jpg, .jpeg, .png";
export const fileAccept = ".pdf, .jpg, .jpeg, .png, .doc, .docx";

export const getDeviceWidth = () => {
    return window.innerWidth;
}
export const isMobileScreen = window.innerWidth <= 460 ? true : false;