// Import files and libraries
import React, { useEffect, useRef, useState } from 'react';
import { IconBack, Spinner } from '../../../svg-icons';
import GetOrderItemStatus from '../../../component/order-item-status';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setComment, setCurrentItem, setShowComment } from '../../../store/trade/reducer';
import { ATTACHMENT_TYPE, DEFAULT_USER_ROLE, ENVIRONMENT, ORDER_ITEM_AR_STATUS, TOAST_TYPE } from '../../../config/constant';
import ItemDetails from '../../../component/item-details';
import { Dialog } from 'primereact/dialog';
import { setClearGeneralState,  updateURLParams } from '../../../store/general/reducer';
import { actionGetOrderItemById, actionUpdateItemStatus } from '../../../store/trade/action';
import * as Yup from 'yup';

import { showToast } from '../../../helper/toast-service-helper';
import { actionGetFileById } from '../../../store/general/action';
import FilePreview from '../../../component/file-preview';


const TradingItemApproval = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const {
        currentItem, showComment, comments
    } = useSelector((state) => state.trade);

    const {
        userData
    } = useSelector((state) => state.auth);

    const {
        urlParams,
        customer_metal_photo
    } = useSelector((state) => state.general);


    // On page function call get the page data and if any table action changes call the api
    useEffect(() => {
        if (urlParams?.view && urlParams?.type && urlParams.type === 'order-item') {
            dispatch(setClearGeneralState());
            dispatch(actionGetOrderItemById(urlParams.view));
        }
    }, [urlParams]);

    const goBack = () => {
        dispatch(setCurrentItem(null));
        navigate(-1); // Navigate to the previous page
        dispatch(updateURLParams());
    };
    const viewFile = (file) => {

        if (file.file_type === 'application/pdf' || (file.file_type).startsWith('image/')) {
            dispatch(actionGetFileById(file.slug, 'sd'));
        }
    };

    return (
        <div className='container-fluid'>
            <div className="card mb-3">
                <div className="card-body">
                    <div className='row'>
                        <div className="col-md-12 mb-3">
                            <div className='d-flex align-items-center'>
                                <IconBack
                                    width={32}
                                    height={32}
                                    onIconClick={goBack}
                                    customClass='cursor-pointer'
                                />
                                <h3 className='ms-2 flex-shrink-1 mb-0'>Trading Item: {currentItem && currentItem.order_item_row_ref_number}</h3>
                            </div>
                        </div>
                        <div className="row mb-3">
                            {/* Column for All Cards */}
                            <div className="col-md-1 col-4 align-self-center">
                                {/* First Card - Image Placeholder */}

                                {customer_metal_photo ? (
                                    <div className='placeholder-item-image-bg cursor-pointer' onClick={e => { viewFile(customer_metal_photo) }}>
                                        <img className='object-fit' src={`data:${customer_metal_photo?.type};base64,${customer_metal_photo?.base64}`} />
                                    </div>
                                ) : (
                                    <div className='placeholder-item-image-bg'>
                                        <img className='object-fit' src={`${ENVIRONMENT.BASE_URL}assets/images/no-image.png`} />
                                    </div>
                                )}
                            </div>
                            <div className="col-md-10 col-8 align-self-center">
                                <table className="table-bg-white table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td className="pe-2">Customer ID:</td>
                                            <td className="ps-2">
                                                <a className="link">{currentItem && currentItem.customer_row_ref_number}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pe-2">Agent ID:</td>
                                            <td className="ps-2">
                                                <a className="link">{currentItem && currentItem.agent_row_ref_number}</a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="pe-2">Trading ID:</td>
                                            <td className="ps-2">{currentItem && currentItem.row_ref_number}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>


                        <div className="col-md-12 mt-2">
                            <h5><strong>Product Details</strong></h5>
                            {currentItem && currentItem && (
                                <ItemDetails
                                    cc={currentItem.customer_contribution}
                                    mc={currentItem.mc_contribution}
                                    items={[currentItem]}
                                />)}
                        </div>

                    </div>
                </div>
            </div>
            <FilePreview />
        </div>
    )
}


export default TradingItemApproval;

