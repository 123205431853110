import React from 'react';
import SingleFilePreview from './single-file-upload';
const MultipleFilePreview = ({files,showDelete=false}) => {
    return (
        <>
        {
            Object.values(files).length > 0 &&
            Object.entries(files).map(([key,file], index) => (
                <div className="col-md-12" key={index}>                                                    
                    <SingleFilePreview file={file} inx={key} showDeleteBtn={showDelete} />
                </div>
            ))
        }
        </>
    );
};

export default MultipleFilePreview;
