import { createSlice } from '@reduxjs/toolkit'
import { SIDEBAR_OPTION } from '../../config/constant';

let url = window.location.href,
  retObject = {},
  parameters;

if (url.indexOf('?') !== -1) {
  url = url.split('?')[1];

  parameters = url.split('&');

  for (var i = 0; i < parameters.length; i++) {
    retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1];
  }

}

const preSideBarActive = sessionStorage.getItem('sidebar');
let sideBarActive = preSideBarActive ? preSideBarActive : SIDEBAR_OPTION.ICON_SIDEBAR;

const initialState = {
  sideBarActive,
  createUserModal: false,
  networkOffline: !window.navigator.onLine,
  showLogin: true,
  pageTitle: 'Madini Chapaa',
  loader: false,
  showPassword: false,
  urlParams: retObject,
  loginSection: true,
  toastStatus: false,
  toastData: {
    severity: null,
    summary: null,
    detail: null
  },
  deviceWidth: 0,
  tableLoader: false,
  disabledButton: false,
  filePreview: null,
  filePreviewModel: false,
  showCamera: null,
  profile: null,
  customer_photo: null,
  metal_photo: null,
  customer_metal_photo: null,
  weight_photo: null,
  purity_photo: null,
  supportingDocuments: {},
  changePasswordModel: false,
  profileLoader: false,
  popupLocation:false,
  
};

const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setSideBarAction(state, action) {
      state.sideBarActive = action.payload;
      action.payload
        ? sessionStorage.setItem('sidebar', action.payload)
        : sessionStorage.removeItem('sidebar');
    },
    
    setNetworkOffline(state, action) {
      state.networkOffline = action.payload;
    },
    setPopupLocation(state, action) {
      
      state.popupLocation = action.payload;
    },
    setProfileLoader(state, action) {
      state.profileLoader = action.payload;
    },
    setChangePasswordModel(state, action) {
      state.changePasswordModel = action.payload;
    },
    setShowCamera(state, action) {
      state.showCamera = action.payload;
    },
    updatePageTitle(state, action) {
      state.pageTitle = `${action.payload} || Madini Chapaa`;
    },
    updateLoaderStatus(state, action) {
      state.loader = action.payload;
    },
   
    updateURLParams(state, action) {
      let url = window.location.href,
        retObject = {},
        parameters;

      if (url.indexOf('?') !== -1) {
        url = url.split('?')[1];

        parameters = url.split('&');

        for (var i = 0; i < parameters.length; i++) {
          retObject[parameters[i].split('=')[0]] = parameters[i].split('=')[1];
        }

      }
      state.urlParams = retObject;
    },

    setLoginSection(state, action) {
      state.loginSection = action.payload;
    },
    setToastStatus(state, action) {
      state.toastStatus = action.payload;
    },
    toastData(state, action) {
      state.toastStatus = action.payload;
    },
    setDeviceWidth(state, action) {
      state.deviceWidth = action.payload;
      if (action.payload <= 460) {
        state.sideBarActive = '';
        sessionStorage.setItem('sidebar', sideBarActive);
      }
    },
    setTableLoader(state, action) {
      state.tableLoader = action.payload;
    },
    setDisabledButton(state, action) {
      state.disabledButton = action.payload;
    },
    setFilePreviewModel(state, action) {
      state.filePreviewModel = action.payload;
    },
    setFilePreview(state, action) {
      state.filePreview = action.payload;
    },

    setIAPlaceholder: (state, action) => {
      const { name, file } = action.payload;
      // Create a placeholder for the file before upload
      state[name] = { placeholder: true, uploadProgress: 0, file };
    },
    setIAProgress: (state, action) => {
      const { name, percentCompleted } = action.payload;
      state[name] = {
        ...state[name],
        uploadProgress: percentCompleted
      };
    },
    setIAData: (state, action) => {
      const { name, data } = action.payload;
      // Replace the placeholder with the uploaded file data
      state[name] = {
        ...data
      };
    },

    setBulkSupportingDocumentData: (state, action) => {
      // Replace the placeholder with the uploaded file data
      state.supportingDocuments = action.payload;
    },
    setSupportingDocumentData: (state, action) => {
     
      // Replace the placeholder with the uploaded file data
      state.supportingDocuments[action.payload.inx] = {
        ...action.payload.data
      };
    },
    setSupportingDocumentPlaceholder: (state, action) => {
      // Create a placeholder for the file before upload
      state.supportingDocuments[action.payload.inx] = { placeholder: true, uploadProgress: 0, file: action.payload.file };
    },
    setDonwloadDocumentPlaceholder: (state, action) => {
      // Create a placeholder for the file before upload
      state.supportingDocuments[action.payload.inx] = {  ...action.payload.fileDetails,downloading:true };
    },
    setSupportingDocumentProgress: (state, action) => {

      state.supportingDocuments[action.payload.inx] = {
        ...state.supportingDocuments[action.payload.inx],
        uploadProgress: action.payload.percentCompleted
      };
    },
    setSupportingDocumentDownloadProgress: (state, action) => {

      state.supportingDocuments[action.payload.inx] = {
        ...state.supportingDocuments[action.payload.inx],
        downloadProgress: action.payload.percentCompleted
      };
    },
    setProDocDefault: (state, action) => {
      state.supportingDocuments = {};
      state.profile = null;
    },
    setDeleteFile: (state, action) => {
      const {inx,type}=action.payload;
      
      if(type){
        state[type] = null;        
      }else{
        const newItems =Object.fromEntries(
          Object.entries(state.supportingDocuments).filter(([key, value]) => inx != key)
        );        
        
        state.supportingDocuments = {...newItems};
      }
    },
    setClearGeneralState: (state, action) => {
      state.supportingDocuments = {};
      state.profile = null;
      state.tableLoader = false;
      state.disabledButton = false;
      state.filePreview = null;
      state.filePreviewModel = false;
      state.loader = false;
      state.customer_photo = null;
      state.metal_photo = null;
      state.customer_metal_photo = null;
      state.weight_photo = null;
      state.purity_photo = null;
      state.supportingDocuments = {};
    },
    setFileData: (state, action) => {
      const { name, value } = action.payload;
      state[name] = value;
    }
  },
});

export default generalSlice.reducer;
export const {
  setSideBarAction,
  setNetworkOffline,
  updatePageTitle,
  updateLoaderStatus,
  updateURLParams,
  setLoginSection,
  setToastStatus,
  toastData,
  setDeviceWidth,
  setTableLoader,
  setDisabledButton,
  setFilePreview,
  setFilePreviewModel,
  setFileData,
  setBulkSupportingDocumentData,
  setSupportingDocumentData,
  setSupportingDocumentPlaceholder,
  setSupportingDocumentProgress,
  setProDocDefault,
  setClearGeneralState,
  setShowCamera,
  setChangePasswordModel,
  setProfileLoader,
  setIAPlaceholder,
  setIAProgress,
  setIAData,
  setPopupLocation,
  setDonwloadDocumentPlaceholder,
  setSupportingDocumentDownloadProgress,
  setDeleteFile
} = generalSlice.actions;
