import React, { useState } from 'react';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux'
import { Dialog } from 'primereact/dialog';

import { IconEyeVisible, IconEyeVisibleOff, Spinner } from '../svg-icons';
import { encryptValue } from '../helper/encryption-helper';
import { setDisabledButton } from '../store/general/reducer';
import { REGEXP } from '../config/constant';
import { setFormData, } from '../store/auth/reducer';
import { setChangePasswordModel, updateLoaderStatus } from '../store/general/reducer';
import { actionChangePassword } from '../store/customer/action';
import { isMobileScreen } from '../helper/common-helper';


const ChangePassword = () => {

  const dispatch = useDispatch();

  const { formData } = useSelector((state) => state.auth);
  const { disabledButton, changePasswordModel} = useSelector((state) => state.general);


  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  

  const [errors, setErrors] = useState({});
  const schema = Yup.object().shape({
    old_password: Yup.string()
      .required('Old Password is required'),
    password: Yup.string()
    .matches(REGEXP.passwordRegExp,  'Password should be minimum 8 characters with 1 uppercase, 1 lowercase, 1 number, and 1 special character')
      .required('Password is required')
      .notOneOf([Yup.ref('old_password')], 'New password must be different from the old password'),
      
    confirm_password: Yup.string()
      .oneOf([formData.password], 'Password do not match')
      .required('Please confirm your password'),
  });
  const validateField = async (name, value) => {
    try {
      await Yup.reach(schema, name).validate(value);
      setErrors({ ...errors, [name]: '' });
    } catch (validationError) {
      setErrors({ ...errors, [name]: validationError.message });
    }
  };
  const validateForm = async () => {
    try {
      await schema.validate(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (validationErrors) {
      const newErrors = validationErrors.inner.reduce((acc, err) => {
        acc[err.path] = err.message;
        return acc;
      }, {});
      setErrors(newErrors);
      return false;
    }
  };
  const resetForm = () => {
    dispatch(
      setFormData({
        old_password: '',
        password: '',
        confirm_password: ''
      })
    );
    setErrors({});
    setShowPassword(false);
    setShowOldPassword(false);
    setShowConfirmPassword(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = await validateForm();
    if (isValid) {
      const finalData = {
        old_password:encryptValue(formData.old_password),
        new_password:encryptValue(formData.password),
      };
      dispatch(setDisabledButton(true));
      dispatch(updateLoaderStatus(true));
      dispatch(actionChangePassword(finalData));

      dispatch(setChangePasswordModel(false));
      resetForm(); // Reset form fields for next open  
    } else {
      console.log('Form has errors:', errors);
    }
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    let data = {
      ...formData,
      [name]: value,
    };

    dispatch(setFormData(data));

    await validateField(name, value);
  };

  const toggleShowHidePassword = () => {
    setShowPassword(!showPassword);
  }
  const toggleConfirmShowHidePassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  }
  const toggleOldShowHidePassword = () => {
    setShowOldPassword(!showOldPassword);
  }
  const handleDialogClose = () => {
    resetForm(); // Reset form fields when closing the dialog
    dispatch(setChangePasswordModel(false)); // Hide dialog
  };
  

  return (
    <Dialog
      header="Change password"
      visible={changePasswordModel}
      style={{ width: isMobileScreen ? '75vw':'25vw' }}
      onHide={handleDialogClose}
      
    >
      <form onSubmit={handleSubmit} noValidate className='pb-3'>

        <div className="form-group mb-3">
          <label>Old password</label>
          <div className="remove-dd input-group mb-3">
            <input type={showOldPassword ? "text" : "password"} className="form-control"
              name="old_password"
              value={formData.old_password}
              onChange={handleChange}
              placeholder='Enter the old password'
            />
            <span
              className="input-group-text"
              id="basic-addon4"
              onClick={toggleOldShowHidePassword}
            >{showOldPassword ? <IconEyeVisibleOff /> : <IconEyeVisible />}</span>
          </div>
          {errors.old_password && <span className="error-message">{errors.old_password}</span>}
        </div>
        <div className="form-group mb-3">
          <label>New password</label>
          <div className="remove-dd input-group mb-3">
            <input type={showPassword ? "text" : "password"} className="form-control"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder='Enter the new password'
            />
            <span
              className="input-group-text"
              id="basic-addon2"
              onClick={toggleShowHidePassword}
            >{showPassword ? <IconEyeVisibleOff /> : <IconEyeVisible />}</span>
          </div>
          {errors.password && <span className="error-message">{errors.password}</span>}
        </div>
        <div className="form-group mb-3">
          <label>Confirm password</label>
          <div className="remove-dd input-group mb-3">
            <input type={showConfirmPassword ? "text" : "password"} className="form-control"
              name="confirm_password"
              value={formData.confirm_password}
              onChange={handleChange}
              placeholder='Enter the confirm password'
            />
            <span
              className="input-group-text"
              id="basic-addon2"
              onClick={toggleConfirmShowHidePassword}
            >{showConfirmPassword ? <IconEyeVisibleOff /> : <IconEyeVisible />}</span>
          </div>
          {errors.confirm_password && <span className="error-message">{errors.confirm_password}</span>}
        </div>
        <div className="form-group text-center">

          <button disabled={disabledButton} type="submit" className="btn-block btn btn-orange-primary">   {disabledButton ? <Spinner /> : null} Submit</button>


        </div>
      </form>
    </Dialog>
  );
};

export default ChangePassword;
